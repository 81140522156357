'use strict';

import isNumber from 'lodash/isNumber';
import EXIF from '../../../../js/exif';
// @ts-ignore
import exifOrient from 'exif-orient/exif-orient';
import {FIX_SETTINGS} from './ts-image-fixer.settings';
import {getRootStore} from '../../app.bootstrap';
import {MobileChatApiService} from '../ts-chat-api/MobileChatApi';

/**
 * Interface for the resize requirement results
 */
interface ResizeRequirement {
    resized: boolean;
    width: number;
    height: number;
}

/**
 * Interface for orientation settings
 */
interface OrientationSettings {
    version: string;
    fix(imageFile: File): Promise<string>;
}

/**
 * Utility service that wraps the functionality required to re-orient and resize an image
 */
export interface ITsImageFixerService {
    isHighImageQuality: boolean;
    fix(imageFile: File): Promise<string>;
}
export class TsImageFixerService implements ITsImageFixerService {
    private isIosAndOver13: boolean;
    private window: Window;
    private EXIF: typeof EXIF;
    private exifOrient: typeof exifOrient;
    private chatApi: MobileChatApiService;
    private settings: typeof FIX_SETTINGS;

    constructor() {
        const osVersion = getRootStore().environmentDetect.isIOS() && getRootStore().environmentDetect.os();

        this.isIosAndOver13 = (osVersion && osVersion.version && parseInt(osVersion.version, 10) >= 13) as boolean;
        this.window = window;
        this.EXIF = EXIF;
        this.exifOrient = exifOrient;
        this.chatApi = getRootStore().chatApi;
        this.settings = FIX_SETTINGS;
    }

    get isHighImageQuality(): boolean {
        return this.chatApi.enableSharing && this.chatApi.imageSharingQuality === 1;
    }

    /**
     * Re-orients and resizes an image file based on EXIF data.
     * @param imageFile - File received from an input tag
     * @returns Promise resolving with the Object URL of the fixed image
     */
    fix(imageFile: File): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const fixStarted = this.EXIF.getData(imageFile, () => {
                    // @ts-ignore
                    const orientation = imageFile.exifdata?.Orientation;

                    this._readToImage(imageFile)
                        .then((image) => {
                            const dataUrl = this._resizeCanvas(image);
                            return this._rotate(dataUrl, orientation);
                        })
                        .then((canvas) => resolve(this._canvasToObjectUrl(canvas)))
                        .catch((err) => reject(err));
                });

                if (!fixStarted) {
                    reject('Image fix failed: failed to retrieve EXIF data');
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    private _dataUrlToImage(dataUrl: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = dataUrl;
        });
    }

    fixDataUrl(dataUrl: string, useHighQuality: boolean): Promise<string> {
        return this._dataUrlToImage(dataUrl).then((image) => {
            const resizeRequirement = this._isResizeRequired(image, useHighQuality);
            if (resizeRequirement.resized) {
                return this._resizeCanvas(image, resizeRequirement);
            }
            return dataUrl;
        });
    }

    /**
     * Takes a file from and html input and reads it to an Image
     *
     * @param imageFile - file to read
     * @return Promise - resolves with newly created Image
     */

    private _readToImage(imageFile: File): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const image = new Image();

            image.onload = () => resolve(image);
            image.onerror = () => reject('Image failed to load');

            reader.onloadend = () => {
                image.src = reader.result as string;
            };

            reader.readAsDataURL(imageFile);
        });
    }

    /**
     * Fixes image rotation
     *
     * @param image - Image to rotate
     * @param exifOrientation - exif orientation
     * @return Promise - resolves with a canvas (created from exif-orient) that
     *                   has the rotated image
     */
    private _rotate(image: string, exifOrientation: number | undefined): Promise<HTMLCanvasElement> {
        return new Promise((resolve, reject) => {
            let orientation = exifOrientation;
            // need to set orientation = 1 when:
            // ios >= 13 AND orientation = (6 OR 3)
            const setOrientation = this.isIosAndOver13 && (orientation === 3 || orientation === 6);

            // Needed for files without exifdata. Even when there's no rotation
            // needed or applicable (no exif) we still use exifOrient, to create the
            // canvas that will be used in later transformations
            if (!isNumber(orientation) || orientation <= 0 || setOrientation) {
                orientation = 1;
            }

            this.exifOrient(image, orientation, (err: Error | null, canvas: HTMLCanvasElement) => {
                if (err) {
                    return reject(err);
                }
                resolve(canvas);
            });
        });
    }

    private _isResizeRequired(image: HTMLImageElement, useHighQuality?: boolean): ResizeRequirement {
        const maxImageSize =
            this.isHighImageQuality || useHighQuality
                ? FIX_SETTINGS.highQualityImageSizeThreshold
                : FIX_SETTINGS.imageSizeThreshold;

        if (image.naturalWidth * image.naturalHeight > maxImageSize) {
            const ratio = image.naturalWidth / image.naturalHeight;
            const newWidth = Math.sqrt(maxImageSize * ratio);
            const newHeight = newWidth / ratio;

            return {resized: true, width: newWidth, height: newHeight};
        }

        return {resized: false, width: image.naturalWidth, height: image.naturalHeight};
    }

    /**
     * Resizes an image in a canvas, if it's over a threshold defined in the settings
     *
     * @param image - image to resize
     * @param size - override resize dimensions (optional)
     * @return resized image dataUrl
     */
    private _resizeCanvas(image: HTMLImageElement, size?: ResizeRequirement): string {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const {width, height} = size || this._isResizeRequired(image);

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL(FIX_SETTINGS.defaultMime, FIX_SETTINGS.imageQuality);
    }

    /**
     * Converts a dataURL that we obtain from the canvas, to an ObjectURL
     * that we can feed to the chat api service
     *
     * @param canvas - canvas to convert
     * @returns ObjectURL - ObjectURL of the image
     */
    private _canvasToObjectUrl(canvas: HTMLCanvasElement): string {
        const dataUrl = canvas.toDataURL(FIX_SETTINGS.defaultMime, FIX_SETTINGS.imageQuality);
        // @ts-ignore
        const URL = this.window.URL;
        const bytes = atob(dataUrl.split(';base64,')[1]);
        const buffer = new ArrayBuffer(bytes.length);
        const rawData = new Uint8Array(buffer);

        for (let i = 0; i < bytes.length; i++) {
            rawData[i] = bytes.charCodeAt(i);
        }

        return URL.createObjectURL(new Blob([rawData], {type: FIX_SETTINGS.defaultMime}));
    }
}

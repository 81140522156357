import get from 'lodash/get';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../_react_/app.bootstrap';

export class TsFlashlightHelper {
    constructor(tsEventService) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.EventService = tsEventService;

        this.isTorchOnHintVisible = false;
        this.isTorchOn = false;

        this._initHandlers();
    }

    initMobileAppMediaService(mobileAppMediaService) {
        this.mobileAppMediaService = mobileAppMediaService;
    }

    // ToDo: need to sort out the issue with common - socketEvents.CLIENT_IN.RUN_TURN_ON_FLASHLIGHT_GUIDE_ACTION doesnt exist.
    // maybe use AGENT_OUT_REQUEST_ACTION.RUN_TURN_ON_FLASHLIGHT_GUIDEinstead
    _initHandlers() {
        const runTurnOnFlashlightGuideListener = (value) => this.showFlashlightGuideHint(value);

        this.chatApi.onReady(() => {
            this.chatApi.requestAction('isTorchOn', this.isTorchOn);
            this.chatApi.on('runTurnOnFlashlightGuideAction', runTurnOnFlashlightGuideListener);
        });
    }

    showFlashlightGuideHint(value) {
        this.isTorchOnHintVisible = value;
        setTimeout(() => (this.isTorchOnHintVisible = false), 1000);
    }

    setTorch(value) {
        this.isTorchOn = value;
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_ON, this.isTorchOn);
    }

    turnFlashlight(turn) {
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            turn ? LOG_EVENTS.flashlightRequestTurnOn : LOG_EVENTS.flashlightRequestTurnOff,
            {}
        );

        return this.mobileAppMediaService.turnFlashlight(turn).then((success) => {
            if (success) {
                this.setTorch(turn);
            }

            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                success ? LOG_EVENTS.flashlightStatus : LOG_EVENTS.flashlightError.type,
                {
                    status: turn
                }
            );

            return success;
        });
    }

    toggleLightMobile() {
        const flashlightTimeout = get(this.chatApi, 'accountSettings.flashlightTimeout', 180) * 1000;

        const turn = !this.isTorchOn;

        return this.turnFlashlight(turn).then((success) => {
            if (!success) {
                return;
            }

            if (this.isTorchOn) {
                return this.mobileAppMediaService.setAutoLightOffTimer(flashlightTimeout).then(() => {
                    this.setTorch(false);

                    return this.isTorchOn;
                });
            }

            return this.isTorchOn;
        });
    }
}

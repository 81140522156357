'use strict';

import './ts-fullscreen-gallery/index';
import './pre-camera-permission/index';

const dependencies = ['ts-fullscreen-gallery', 'pre-camera-permission-module'];

import {tsFileLoaderDirective} from './ts-file-loader/ts-file-loader.directive';
import {tsIframeOverlayDirective} from './ts-iframe-overlay/ts-iframe-overlay.directive';
import {tsZoomableImageDirective} from './ts-zoomable-image/ts-zoomable-image.directive';
import {tsVideoPlayerDirective} from './ts-video-player/ts-video-player.directive';
import {tsEndMeetingButtonDirective} from './ts-end-meeting-button/ts-end-meeting-button.directive';
import {tsBusySpinnerDirective} from './ts-busy-spinner/ts-busy-spinner.directive';
import {tsChatControlDirective} from './ts-chat-control/ts-chat-control.directive';
import {tsPopupDirective} from './ts-popup/ts-popup.directive';
import {tsActionGuidanceDirective} from './ts-action-guidance/ts-action-guidance.directive';
import {tsStateTitleDirective} from './ts-state-title/ts-state-title.directive';
import {tsCobrowsingTitleDirective} from './ts-cobrowsing-title/ts-cobrowsing-title.directive';
import {tsChatDirective} from './ts-chat/ts-chat.directive';
import {tsCobrowsingControlDirective} from './ts-cobrowsing-control/ts-cobrowsing-control.directive';
import {tsBusyBackgroundDirective} from './ts-busy-background/ts-busy-background.directive';
import {tsRoundedButtonDirective} from './ts-rounded-button/ts-rounded-button.directive';
import {tsConnectToCenterDirective} from './ts-connect-to-center/ts-connect-to-center.directive';
import {tsChatConnectDirective} from './ts-chat-connect/ts-chat-connect.directive';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {BorderRoundedIcon} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {ErrorMessageCloseSession} from './error-message-close-session/component';
import {MessageAlert} from '@techsee/techsee-ui-common/lib/message-alert';
import {EndMeeting} from '../_react_/states/meeting/components/modal/end-meeting/EndMeetingComponent';
import {PermissionModal} from '../_react_/states/components/generic/permission-modal';

export default angular
    .module('app.components', dependencies)
    .directive('tsFileLoader', tsFileLoaderDirective)
    .directive('tsIframeOverlay', tsIframeOverlayDirective)
    .directive('tsZoomableImage', tsZoomableImageDirective)
    .directive('tsVideoPlayer', tsVideoPlayerDirective)
    .directive('tsEndMeetingButton', tsEndMeetingButtonDirective)
    .directive('tsBusySpinner', tsBusySpinnerDirective)
    .directive('tsChatControl', tsChatControlDirective)
    .directive('tsCobrowsingControl', tsCobrowsingControlDirective)
    .directive('tsPopup', tsPopupDirective)
    .directive('tsActionGuidance', tsActionGuidanceDirective)
    .directive('tsStateTitle', tsStateTitleDirective)
    .directive('tsChat', tsChatDirective)
    .directive('tsRoundedButton', tsRoundedButtonDirective)
    .directive('tsBusyBackground', tsBusyBackgroundDirective)
    .directive('tsCobrowsingTitle', tsCobrowsingTitleDirective)
    .directive('tsConnectToCenter', tsConnectToCenterDirective)
    .directive('tsChatConnect', tsChatConnectDirective)
    .directive('tsConfirmationModal', (reactDirective) => reactDirective(ConfirmationModal))
    .directive('borderRoundedIcon', (reactDirective) => reactDirective(BorderRoundedIcon))
    .directive('tsErrorMessageCloseSession', (reactDirective) => reactDirective(ErrorMessageCloseSession))
    .directive('tsMessageAlert', (reactDirective) => reactDirective(MessageAlert))
    .directive('tsEndMeeting', (reactDirective) => reactDirective(EndMeeting))
    .directive('tsPermissionModal', (reactDirective) => reactDirective(PermissionModal));

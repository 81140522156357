import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {CoBrowserControlsEnum} from '@techsee/techsee-common/lib/constants/account.constants';
import {TIMEOUT, COBROWSERS} from './ts-cobrowsing-service.settings';
import {CoBrowsingServiceBase} from './CoBrowsingServiceBase';
import {COBROWSING_EVENTS} from '@techsee/techsee-common/lib/constants/room.constants';
import {getMeetingTracer} from '../../states/meeting/meeting.tracer';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../_react_/app.bootstrap';
const trace = getMeetingTracer('CoBrowsing');

export class TsCobrowsingService extends CoBrowsingServiceBase {
    constructor(db, $rootScope, $timeout, tsEventService) {
        'ngInject';

        super(false, getRootStore().chatApi, db, tsEventService);

        this.chatApi = getRootStore().chatApi;
        this.db = db;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.tsEventService = tsEventService;

        this.chatApi.on(socketEvents.CLIENT_IN.COBROWING_PAUSE_ACTION, (pause) => {
            this.isPaused = pause;
            this._updateCobrowsingControls(pause, CoBrowserControlsEnum.pause);
        });

        this.chatApi.on(socketEvents.CLIENT_IN.CHANGE_COBROWSING_URL_ACTION, (url) => {
            this._updateCobrowsingControls(url, CoBrowserControlsEnum.url);
        });

        this.chatApi.on(socketEvents.CLIENT_IN.SHOW_LOADER_ACTION, (value) => {
            this.otherSideLoading = value;
            if (!value && !this.showLoader) {
                this.$rootScope.safeApply();
            }

            value ? this._loaderTimeout() : this.$timeout.cancel(this._currentTimer);
        });

        this.on(COBROWSING_EVENTS.SESSION_STARTED, () => {
            this._showHideLoader(false);

            if (this.agentInControlLastState) {
                setTimeout(() => this.giveControlToFollower(), 0);
            }
        });

        this.on(COBROWSING_EVENTS.VIEWER_JOINED, (session) => {
            if (this._isCurrentSession(session)) {
                trace.info('Co Browsing follower joined');
            }
        });

        this.on(COBROWSING_EVENTS.VIEWER_LEFT, (session) => {
            if (this._isCurrentSession(session)) {
                trace.info('Co Browsing folllower left');
            }
        });

        this.on(COBROWSING_EVENTS.CONTROL_SWITCH, (agentInControl) => {
            trace.info(`Co Browsing switch control, agentInControl: ${agentInControl}`);
        });

        this.on(COBROWSING_EVENTS.ERROR, (error) => {
            trace.info(`Co Browsing error: ${error}`);
        });
    }

    _isCurrentSession(session) {
        return get(session, '_sessionId') === get(this.chatApi, 'dashboard.coBrowsingSettings.sessionId');
    }

    start(accountId, roomId, widgetKey) {
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        this.roomId = roomId;
        this.accountId = accountId;
        this.isPaused = get(this.chatApi, 'dashboard.coBrowsingSettings.isPaused');

        this.getSurflyObject(window, widgetKey)
            .then(() => this._initSession())
            .catch((err) => this.emit(COBROWSING_EVENTS.INIT_ERROR, err))
            .finally(() => (this.inProgress = false));
    }

    _showHideLoader(value) {
        this.showLoader = value;
        this.$rootScope.safeApply();
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.SHOW_LOADER, value);

        value ? this._loaderTimeout() : this.$timeout.cancel(this._currentTimer);
    }

    _loaderTimeout() {
        if (this._currentTimer) {
            this.$timeout.cancel(this._currentTimer);
        }

        this._currentTimer = this.$timeout(() => {
            if (this.showLoader) {
                const iframeId = '#coBrowsingTechSeeLeaderFrame';

                $(iframeId)[0].src += '';
                this._currentTimer = null;
            }
        }, TIMEOUT.LOADER_TIMEOUT);
    }

    _initSession() {
        const url = get(this.chatApi.dashboard, 'coBrowsingSettings.leaderLink');
        // eslint-disable-next-line no-unused-vars
        const agentInControl = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');

        if (!url || this.url === url) {
            return;
        }

        this.url = url;

        this.agentInControlLastState = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');

        super.initSession(url);
        this._showHideLoader(true);
    }

    _updateCobrowsingControls(value, controlState) {
        const settings = get(this.chatApi, 'dashboard.coBrowsingSettings');

        if (settings) {
            const updatedSettings = cloneDeep(settings);

            switch (controlState) {
                case CoBrowserControlsEnum.pause:
                    updatedSettings.isPaused = value;
                    break;
                case CoBrowserControlsEnum.control:
                    updatedSettings.agentInControl = value;
                    break;
                case CoBrowserControlsEnum.url:
                    updatedSettings.url = value;
                    break;
                default:
                    break;
            }

            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.COBROWSING_SETTINGS, updatedSettings);
        }
    }

    giveControlToFollower() {
        return this.sessionObject && this.sessionObject.giveControl(COBROWSERS.FOLLOWER);
    }

    paused() {
        this.isPaused = !this.sessionObject.paused;
        this._updateCobrowsingControls(this.isPaused, CoBrowserControlsEnum.pause);
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.COBROWING_PAUSE, this.isPaused);

        if (this.sessionObject && this.isInControl) {
            this.sessionObject.pause();

            this.tsEventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                LOG_EVENTS.coBrowsingSurflySwitchPaused,
                {
                    isPaused: true
                }
            );
        }
    }

    resume() {
        this.isPaused = !this.sessionObject.paused;
        this._updateCobrowsingControls(this.isPaused, CoBrowserControlsEnum.pause);
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.COBROWING_PAUSE, this.isPaused);

        if (this.sessionObject && this.isInControl) {
            this.sessionObject.resume();

            this.tsEventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                LOG_EVENTS.coBrowsingSurflySwitchResume,
                {
                    isPaused: false
                }
            );
        }
    }

    get isInSession() {
        return !!this._sessionObject;
    }

    get EVENTS() {
        return COBROWSING_EVENTS;
    }
}

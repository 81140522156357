import {EVENT_SOURCES} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {TraceOutputData} from '@techsee/techsee-common/lib/core/Tracer';
import {TsExtraLoggingService} from '@techsee/tracer';
import {IVerboseLogging} from '@techsee/techsee-common/lib/constants/account.constants';

export interface ITsEventService {
    setRoomDetails(roomId: string, roomCode: string): void;
    setVerboseSettings(verboseSettings: IVerboseLogging): void;
    traceToEventLogOutputFunction(traceData: TraceOutputData): void;
    sendEventLog(userId: string | null, roomId: string | null, type: string, meta: any): void;
}

export class TsEventService {
    private db: any;

    private _extraLoggingService: TsExtraLoggingService;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['db'];

    constructor(db: any) {
        'ngInject';

        this.db = db;

        this._extraLoggingService = new TsExtraLoggingService(PlatformType.mobile_web, CLIENT_VERSION);
        this.traceToEventLogOutputFunction = this.traceToEventLogOutputFunction.bind(this);
    }

    setRoomDetails(roomId: string, roomCode: string) {
        this._extraLoggingService.setRoomDetails(roomId, roomCode);
    }

    setVerboseSettings(
        verboseSettings: IVerboseLogging,
        userId?: string,
        accountId?: string,
        roomId?: string,
        roomCode?: string
    ) {
        this._extraLoggingService.setVerboseSettings(verboseSettings, userId, accountId, roomId, roomCode);
    }

    async traceToEventLogOutputFunction(traceData: TraceOutputData) {
        await this._extraLoggingService.traceToEventLogOutputFunction(traceData);
    }

    isEmpty(value: string | null) {
        if (!value || value === 'none' || value === null) {
            return false;
        }

        return true;
    }
    sendEventLog(userId: string | null, roomId: string | null, type: string, meta: any) {
        return this.db.EventLog.create({
            accountId: this._extraLoggingService.accountId ?? 'none',
            userId: !this.isEmpty(userId) ? userId : (this._extraLoggingService.userId ?? 'none'),
            room: !this.isEmpty(roomId) ? roomId : (this._extraLoggingService.roomId ?? 'none'),
            type: type,
            sentBy: EVENT_SOURCES.client,
            meta: meta
        });
    }
}

'use strict';

import get from 'lodash/get';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

import messageView from '../../states/meeting/video-chat/dialogs/message.view.html';
import {MessageController} from '../../states/meeting/video-chat/dialogs/message.controller.js';
import {CHAT_CONTROL_BLUR_DEBOUNCE_TIMEOUT} from './ts-chat-helper.settings';
import {
    MEDIA_COMPLETION_POPUP_TIMEOUT,
    ROOM_COUNTER_TYPES,
    STATUS_MESSAGES,
    VIEW_PAGES
} from '../../states/meeting/meeting.settings.js';
import {VIDEO_SIZES, getVideoStyle} from '../../directives/ts-video-publisher/ts-video-publisher.directive';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

// Dialog controller and view
import mediaUploadingView from '../../states/meeting/photo-chat/dialogs/media-uploading.view.html';
import {MediaUploadingController} from '../../states/meeting/photo-chat/dialogs/media-uploading.controller';
// @ts-ignore
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import {UserType, MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {sanitizeChatMessage} from '@techsee/techsee-common/lib/utils';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

import {getMeetingTracer} from '../../states/meeting/meeting.tracer';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import {getRootStore} from '../../_react_/app.bootstrap';

const trace = getMeetingTracer('TsChatHelperService');

const PARTIAL_SET_STATE = {
    PARTIAL: 'PARTIAL',
    COMPLETE: 'COMPLETE'
};

export class TsChatHelper {
    /* eslint max-params: 0 */

    constructor(
        $rootScope,
        $timeout,
        $uibModal,
        $window,
        $translate,
        EndMeetingConfirmationModal,
        db,
        tsFullscreenGalleryService,
        tsStateHelper,
        tsEventService,
        uploadConfirmationModal
    ) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.db = db;
        this.tsAppstoreUrlUtils = getRootStore().appstoreUrlUtils;
        this.$uibModal = $uibModal;
        this.$window = $window;
        this.$translate = $translate;
        this.stateHelper = tsStateHelper;
        this.EndMeetingConfirmationModal = EndMeetingConfirmationModal;
        this.$timeout = $timeout;
        this.tsFullscreenGalleryService = tsFullscreenGalleryService;
        this._setCurrentPage(VIEW_PAGES.MAIN);
        this.getVideoStyle = getVideoStyle;
        this.videoSettings = {enabled: false};
        this.showImageUploadSuccess = false;
        this.showImageUploadFailure = false;
        this.showVideoUploadSuccess = false;
        this.showVideoUploadFailure = false;
        this.EventService = tsEventService;
        this.videoFixer = getRootStore().videoFixer;
        this.uploadConfirmationModal = uploadConfirmationModal;
        this.partialSetsOfImageWithSteps = [];
        this.failedMessageOffset = 0;
        this.imageUploadRetryIndex = -1;
        this.PARTIAL_SET_STATE = PARTIAL_SET_STATE;
        this.VIDEO_SIZES = VIDEO_SIZES;
        this.messageHistory = {
            messages: [],
            imageCount: 0,
            videoCount: 0,
            pendingMessages: [],
            pendingMessagesCount: 0
        };

        this._appendHistoryMessage = this._appendHistoryMessage.bind(this);
        this._setDashboardUA = this._setDashboardUA.bind(this);
        this.finishMeeting = this.finishMeeting.bind(this);

        this.chatApi.on(socketEvents.CLIENT_IN.MESSAGE, this._appendHistoryMessage);
        this.chatApi.on(socketEvents.CLIENT_IN.SYNC, this._setDashboardUA);
    }

    setMessageHistory(messageHistory) {
        this.messageHistory = messageHistory;
    }

    async _setDashboardUA() {
        const dashboardUA = get(this.chatApi, 'dashboard.browser.userAgent');

        if (dashboardUA !== this.dashboardUA) {
            this.dashboardUA = dashboardUA;
            this.tsDashboardEnvironmentDetect = new TsEnvironmentDetect(dashboardUA, undefined, true);
        }
    }

    _setCurrentPage(page) {
        if (page === this.currentPage) {
            return;
        }

        if (this.isInImagePage) {
            this._closeFullscreenGallery();
        }

        if (this.isInChatPage) {
            this.messageHistory.messages = this.setUnreadPropertyMessage(this.messageHistory);
        }

        // IMAGE page does not show the chat control, so it should be de-focused automatically
        if (page === VIEW_PAGES.IMAGE) {
            this.chatControlFocused = false;
        }

        this.previousPage = this.currentPage;
        this.currentPage = page || VIEW_PAGES.MAIN;

        if (this.videoSettings) {
            this.videoSettings.videoSize = this._decideOnVideoSize();
        }
    }

    selectedImage(message) {
        this.displayFullscreenGallery(this.tsFullscreenGalleryService.$context.MESSAGE_CLICKED, message.index);
    }

    displayFullscreenGallery(context, data) {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_IN_PHOTOGALLERY_PAGE
        );
        this.tsFullscreenGalleryService.openGallery(context, data);
        this._setCurrentPage(VIEW_PAGES.IMAGE);
    }

    _closeFullscreenGallery() {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_CLOSED_PHOTOGALLERY_PAGE
        );
        this.tsFullscreenGalleryService.closeGallery();
    }

    expandButtonClick(disabled) {
        if (this.chatControlFocused || disabled) {
            return;
        }

        if (this.isInMainPage) {
            return this._setCurrentPage(VIEW_PAGES.CHAT);
        }

        if (this.isInChatPage) {
            return this._setCurrentPage(VIEW_PAGES.MAIN);
        }

        // From IMAGE state we should return to the previous state
        return this._setCurrentPage(this.previousPage);
    }

    get isInSpeedtestPage() {
        return this.currentPage === VIEW_PAGES.SPEEDTEST;
    }

    setPage(page) {
        return this._setCurrentPage(page);
    }

    get isInMainPage() {
        return this.currentPage === VIEW_PAGES.MAIN;
    }

    get isMobileDashboard() {
        return this.tsDashboardEnvironmentDetect && this.tsDashboardEnvironmentDetect.isMobile();
    }

    get isInChatPage() {
        return this.currentPage === VIEW_PAGES.CHAT;
    }

    get isInImagePage() {
        return this.currentPage === VIEW_PAGES.IMAGE;
    }

    onChatControlFocused(isFocused) {
        this.$timeout(() => {
            this.chatControlFocused = isFocused;
        }, CHAT_CONTROL_BLUR_DEBOUNCE_TIMEOUT);
    }

    showChatControl() {
        this.allowClientChat = get(this.chatApi, 'accountSettings.allowClientChat');

        return (
            this.allowClientChat &&
            this.currentPage !== VIEW_PAGES.IMAGE &&
            !this.isInSpeedtestPage &&
            !this.isMobileDashboard
        );
    }

    _decideOnVideoSize() {
        return this.isInMainPage ? VIDEO_SIZES.NORMAL : VIDEO_SIZES.THUMBNAIL;
    }

    videoClicked() {
        if (!this.isInMainPage) {
            this._setCurrentPage(VIEW_PAGES.MAIN);
        }
    }

    setUnreadPropertyMessage(messageHistory) {
        return forEach(messageHistory.messages, (msg) => {
            msg.unread = false;
        });
    }

    _checkWaitForImageSet(stepMsg, parsedStepMsg) {
        const stepCount = get(stepMsg, 'data.meta.stepCount');
        const setId = get(stepMsg, 'data.meta.setId');
        const metaStep = get(stepMsg, 'data.meta');
        const newStep = assign(parsedStepMsg, metaStep);

        if (!setId || !stepCount || stepCount < 2) {
            return [newStep];
        }

        const partialSet = this.partialSetsOfImageWithSteps[setId];

        if (!partialSet) {
            this.partialSetsOfImageWithSteps[setId] = [newStep];

            return;
        }

        this.partialSetsOfImageWithSteps[setId].push(newStep);

        // If not all steps arrived yet
        if (partialSet.length < stepCount) {
            return;
        }

        const orderedSet = sortBy(partialSet, (step) => step.stepIndex);

        delete this.partialSetsOfImageWithSteps[setId];

        return orderedSet;
    }

    _getMessageIndex(msg, sentByDashboard) {
        if (msg.failed) {
            this.failedMessageOffset += 1;

            return this.messageHistory.messages.length + 1;
        }

        if (this.imageUploadRetryIndex !== -1 && sentByDashboard) {
            this.failedMessageOffset -= 1;

            return this.imageUploadRetryIndex + 1;
        }

        return msg.storageIndex + this.failedMessageOffset;
    }

    /*
     * Takes a message from either the server or the client itself and
     * pushes it in the message list, after processing it to
     * be 'friendlier' to the view.
     *
     * @param msg - Incoming message
     */

    _appendHistoryMessage(message, skipNotify) {
        let isPartialSet = null;
        let firstMessageInSet = null;
        const isImage = message.data.type === 'image';
        const isVideo = message.data.type === 'video';
        const isText = message.data.type === 'text';

        const sentByDashboard = message.sender === UserType.dashboard;
        const newMessageFromDashboard = message.isNew && sentByDashboard;

        if (newMessageFromDashboard) {
            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.RECEIVED_MESSAGE,
                sanitizeChatMessage(assign({side: PlatformType.mobile_web}, message.data))
            );
        }

        if (newMessageFromDashboard && isImage) {
            this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_RECEIVED_ANNOTATED_IMAGES, message);
        }

        const messageIndex = this._getMessageIndex(message);

        const parsedMsg = {
            isNew: message.isNew,
            type: isImage ? 'IMG-' : isVideo ? 'VID-' : 'MSG-',
            data: isVideo ? message.data.extra : message.data.message,
            video: message.data.message,
            isVideo: isVideo,
            storageIndex: messageIndex,
            unread: newMessageFromDashboard,
            failed: message.failed,
            pending: message.pending
        };

        if ((parsedMsg.failed || parsedMsg.pending) && message.data && message.data.mediaName) {
            parsedMsg.mediaName = message.data.mediaName;
        }

        if (sentByDashboard && isText && this.tsAppstoreUrlUtils.isAppstoreLink(parsedMsg.data)) {
            parsedMsg.appStoreLink = true;
        }

        parsedMsg.type += sentByDashboard ? 'DB' : 'CL';

        const _handleImage = (parsedMsg) => {
            if (isImage && !message.failed && !message.pending) {
                parsedMsg.index = ++this.messageHistory.imageCount;
            } else if (isVideo) {
                parsedMsg.index = ++this.messageHistory.videoCount;
            }

            if (!isImage || this.imageUploadRetryIndex === -1 || sentByDashboard) {
                this.messageHistory.messages.push(parsedMsg);
            } else {
                const failedMessageObjectUrl = this.messageHistory.messages[this.imageUploadRetryIndex].data;

                this.$window.URL.revokeObjectURL(failedMessageObjectUrl);

                this.messageHistory.messages[this.imageUploadRetryIndex] = parsedMsg;
                this.imageUploadRetryIndex = -1;
            }
        };

        const imageFromDashboardWithSteps = sentByDashboard && isImage && get(message, 'data.meta.stepCount');

        if (!imageFromDashboardWithSteps) {
            _handleImage(parsedMsg);
        } else {
            const allImageSteps = this._checkWaitForImageSet(message, parsedMsg);

            if (allImageSteps) {
                forEach(allImageSteps, (stepMsg) => _handleImage(stepMsg));
            }

            isPartialSet = allImageSteps ? PARTIAL_SET_STATE.COMPLETE : PARTIAL_SET_STATE.PARTIAL;

            if (isPartialSet === PARTIAL_SET_STATE.COMPLETE) {
                firstMessageInSet = allImageSteps[0];
            }
        }

        this.messageHistory.messages = this.messages;
        this.messageHistory.pendingMessages = this.pendingMessages;
        this.messageHistory.pendingMessagesCount = this.messageHistory.pendingMessages.length;

        // Notify the server that the client got the message
        if (newMessageFromDashboard) {
            if (isImage) {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_IMAGE, message.timestamp);
            } else if (isVideo) {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_VIDEO, message.timestamp);
            } else {
                this.chatApi.sendLog(STATUS_MESSAGES.GOT_TEXT, message.timestamp);
            }
        } else if (message.isNew && isText) {
            this.chatApi.sendLog(STATUS_MESSAGES.SENT_TEXT, message.timestamp);
        }

        if (skipNotify !== true) {
            this.chatApi.notifyNewHistoryMessage({message: message, isPartialSet, firstMessageInSet});
        }
    }

    get messages() {
        return filter(this.messageHistory.messages, (message) => message.pending !== false);
    }

    get pendingMessages() {
        return filter(this.messageHistory.messages, (message) => message.pending);
    }

    pendingMessageText(textMessage) {
        this.messageHistory.messages.push({
            data: textMessage,
            isNew: true,
            isVideo: false,
            pending: true,
            type: 'MSG-CL',
            unread: false,
            video: textMessage
        });

        this.messageHistory.pendingMessages = filter(this.messageHistory.messages, (message) => message.pending);
        this.messageHistory.pendingMessagesCount = this.messageHistory.pendingMessages.length;
    }

    displayStateTitle() {
        return (
            (this.chatApi.areBothSidesConnected && !this.isInSpeedtestPage) ||
            (this.chatApi.offlineRoom &&
                this.chatApi.client.mode !== MeetingMode.video &&
                this.chatApi.client.mode !== MeetingMode.screen)
        );
    }

    showGallery(messageHistory, messageToFocusOn) {
        return this.$uibModal.open({
            animation: true,
            template: messageView,
            controller: MessageController,
            controllerAs: 'vm',
            windowClass: 'meeting-message-modal',
            resolve: {
                messages: () => messageHistory.messages,
                messageToFocusOn: () => messageToFocusOn
            }
        });
    }

    _uploadConfirmationRequired(confirmationRequired) {
        return (
            confirmationRequired &&
            get(this.chatApi, 'accountSettings.enableUploadConfirmationStep') &&
            this.chatApi.connected
        );
    }

    _validateVideoSize(videoSrc, storedVideoMaxSizeInMb) {
        return videoSrc.size <= storedVideoMaxSizeInMb * 1000000;
    }

    _buildOverMaxSizeErrorString(videoSrc, storedVideoMaxSizeInMb) {
        return this.$translate('FAILED_UPLOAD.VIEW.VIDEO_OVER_MAX_SIZE').then((m) => {
            const videoSizeInMb = (videoSrc.size / 1000000).toFixed(2);
            const videoMaxDuration = Math.ceil((storedVideoMaxSizeInMb / videoSizeInMb) * videoSrc.duration);
            const formatString = (str, args) => str.replace(/\{\s*([^}\s]+)\s*\}/g, (m, iArg) => args[iArg]);

            return formatString(m, [videoMaxDuration]);
        });
    }

    /*
     *   options: { photoSrc, videoSrc, origin, withConfirm, withLoader, retryIndex }
     *
     *   When confirmation required and user click cancel on confirmation, this function rejects with "null" param.
     *   When uploading to AWS failed or uploaded media url not exists, the function rejects with photoSrc/videoSrc and error.
     * */
    sendMedia(options) {
        const {photoSrc, videoSrc, origin, withConfirm, withLoader, retryIndex, isPending, imageSendingCompletion} =
            options;
        let retryMediaFileName = null;

        if (retryIndex === 0 || retryIndex > 0) {
            this.imageUploadRetryIndex = retryIndex;
            retryMediaFileName = this.messageHistory.messages[retryIndex].mediaName;

            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_RESENDING_IMAGE,
                {mediaFileName: retryMediaFileName}
            );
        }

        const isVideo = !!videoSrc;
        const mediaSrc = isVideo ? videoSrc : photoSrc;

        if (isVideo) {
            const storedVideoMaxSizeInMb = get(this.chatApi, 'accountSettings.storedVideoMaxSizeInMb') || 5;

            if (!this._validateVideoSize(mediaSrc, storedVideoMaxSizeInMb)) {
                return this._buildOverMaxSizeErrorString().then((err) => {
                    this._videoUploadFailHandler(err, retryMediaFileName, withLoader);

                    return Promise.reject(err);
                });
            }
        }

        let thumbnailImage = null;

        const createThumbnailPromise = isVideo
            ? this.videoFixer.createThumbnail(mediaSrc.file)
            : Promise.resolve(mediaSrc);

        const uploadConfirmation = (imageToConfirm) => {
            if (!this._uploadConfirmationRequired(withConfirm)) {
                return Promise.resolve(true);
            }

            return this._getUploadConfirmation(imageToConfirm).catch((err) => {
                this._videoUploadFailHandler(err, retryMediaFileName, withLoader);

                return false;
            });
        };

        return new Promise((resolve, reject) => {
            return createThumbnailPromise
                .then((imageToConfirm) => {
                    if (isVideo) {
                        thumbnailImage = imageToConfirm;
                    }

                    return uploadConfirmation(imageToConfirm);
                })
                .then((gotConfirmation) => {
                    if (!gotConfirmation) {
                        reject(null);

                        return;
                    }

                    const url = isVideo ? mediaSrc.url : mediaSrc;

                    this.chatApi
                        .generateMediaFileName(url, isVideo, retryMediaFileName)
                        .then(({blob, mediaFileName}) => {
                            retryMediaFileName = mediaFileName;

                            this.chatApi.sendLog(
                                isVideo
                                    ? STATUS_MESSAGES.CUSTOMER_UPLOADING_A_VIDEO
                                    : STATUS_MESSAGES.CUSTOMER_UPLOADING_AN_IMAGE
                            );
                            this.EventService.sendEventLog(
                                'none',
                                this.chatApi.roomId || 'none',
                                isVideo
                                    ? STATUS_MESSAGES.CUSTOMER_UPLOADING_A_VIDEO
                                    : STATUS_MESSAGES.CUSTOMER_UPLOADING_AN_IMAGE,
                                {origin: origin, mediaFileName: mediaFileName}
                            );

                            if (withLoader) {
                                this.toggleWaitDialog(true);
                            }

                            return {blob, mediaFileName};
                        })
                        .then(({blob, mediaFileName}) => this.chatApi.uploadRoomMedia(blob, isVideo, mediaFileName))
                        .then((result) => {
                            this.$window.URL.revokeObjectURL(url);

                            if (!result || !result.mediaUrl) {
                                const err = {photoSrc: url, error: 'There was no publicUrl in the response from aws'};

                                if (isVideo) {
                                    this._videoUploadFailHandler(err, retryMediaFileName, withLoader);
                                } else {
                                    this._imgUploadFailHandler(
                                        err,
                                        retryMediaFileName,
                                        imageSendingCompletion,
                                        withLoader
                                    );
                                }

                                reject(err);

                                return;
                            }

                            if (withLoader) {
                                this.toggleWaitDialog(false);
                            }

                            if (!isVideo) {
                                return this.chatApi
                                    .sendImage(result.mediaUrl, {
                                        sharedBy: senderTypes.CLIENT,
                                        mediaFileName: retryMediaFileName
                                    })
                                    .then(() => {
                                        this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_SENT_IMAGES);

                                        this._imageSent(true, isPending, imageSendingCompletion);

                                        if (this.imageUploadRetryIndex !== -1) {
                                            delete this.messageHistory.messages[retryIndex].failed;
                                        }

                                        resolve(result);
                                    })
                                    .catch((err) => {
                                        this._imgUploadFailHandler(
                                            'skip',
                                            retryMediaFileName,
                                            imageSendingCompletion,
                                            withLoader
                                        );

                                        reject(err);
                                    });
                            }

                            const thumbnailFileName = this.videoFixer
                                .getThumbnailUrl(result.mediaUrl.split('?')[0])
                                .replace(/\/$/, '')
                                .split('/')
                                .pop();

                            this.chatApi
                                .uploadRoomMedia(thumbnailImage, false, thumbnailFileName)
                                .then((thumbnailResult) => {
                                    this.chatApi.sendVideo(result.mediaUrl, thumbnailResult.mediaUrl, {
                                        sharedBy: senderTypes.CLIENT,
                                        mediaFileName: retryMediaFileName
                                    });
                                    this.chatApi.incrementCounter(ROOM_COUNTER_TYPES.CLIENT_SENT_VIDEOS);

                                    this._videoSent(true, isPending);

                                    resolve({video: result, thumbnail: thumbnailResult});
                                })
                                .catch((err) => {
                                    this._videoUploadFailHandler(
                                        'Failed to upload thumbnail ' + err,
                                        retryMediaFileName,
                                        withLoader
                                    );

                                    reject(err);
                                });
                        })
                        .catch((err) => {
                            if (isVideo) {
                                this._videoUploadFailHandler(err, retryMediaFileName, withLoader);
                            } else {
                                this._imgUploadFailHandler(err, retryMediaFileName, imageSendingCompletion, withLoader);
                            }

                            reject(err);
                        });
                });
        }).catch((err) => {
            if (err) {
                if (this.imageUploadRetryIndex === -1) {
                    const historyMessage = {
                        data: {
                            type: isVideo ? 'video' : 'image',
                            message: isVideo ? videoSrc : photoSrc,
                            mediaName: retryMediaFileName
                        },
                        failed: this.chatApi.connected,
                        pending: !this.chatApi.connected,
                        sender: UserType.client,
                        index: this.messageHistory.messages.length + 1
                    };

                    this._appendHistoryMessage(historyMessage, true);
                }

                this.imageUploadRetryIndex = -1;
            }

            return Promise.reject(err);
        });
    }

    setMode() {
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PREPARING, false);
    }

    toggleWaitDialog(enable) {
        if (enable && !this.modalInstance) {
            this.modalInstance = this.$uibModal.open({
                animation: false,
                template: mediaUploadingView,
                controller: MediaUploadingController,
                controllerAs: 'vm',
                windowClass: 'media-uploading-modal',
                backdrop: false,
                resolve: {
                    newMode: () => true,
                    title: () => 'MEDIA_UPLOADING.VIEW.SENDING_IMAGE'
                }
            });
            this.modalInstance.result.catch(() => false);
        } else if (!enable && this.modalInstance) {
            this.modalInstance.close();
            this.modalInstance = null;

            return true;
        }
    }

    endMeeting(endParams, style) {
        this.EndMeetingConfirmationModal.show(style)
            .then(() => this.finishMeeting(endParams))
            .catch(() => false);
    }

    finishMeeting(endParams) {
        return Promise.resolve()
            .then(() => {
                this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING);
                this.EventService.sendEventLog(
                    'none',
                    this.chatApi.roomId || 'none',
                    STATUS_MESSAGES.CUSTOMER_ENDED_THE_MEETING
                );
                this.chatApi.disconnect(get(this.chatApi, 'accountSettings.customerEndSessionWithoutClosingRoom'));
            })
            .then(() => {
                this.chatApi.unlock();
                const tracePromise = () => trace.info('Photo controller end meeting by customer: ');

                return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(() =>
                    this.stateHelper.safeGo('endNew', endParams)
                );
            })
            .catch(() => {
                this.chatApi.unlock();
            });
    }

    _imgUploadFailHandler(err, mediaFileName, imageSendingCompletion, progressLoaderToggle = true) {
        if (err !== 'skip') {
            this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_S3_IMAGE_UPLOAD_FAILED);
            this.EventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_S3_IMAGE_UPLOAD_FAILED,
                {
                    error: err,
                    mediaFileName: mediaFileName
                }
            );
        }

        if (progressLoaderToggle) {
            this.toggleWaitDialog(false);
        }

        this._imageSent(false, !this.chatApi.connected, imageSendingCompletion);
    }

    _videoUploadFailHandler(err, mediaFileName, progressLoaderToggle = true) {
        this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_S3_VIDEO_UPLOAD_FAILED);
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.CUSTOMER_S3_VIDEO_UPLOAD_FAILED,
            {
                error: err,
                mediaFileName: mediaFileName
            }
        );

        if (progressLoaderToggle) {
            this.toggleWaitDialog(false);
        }

        this._videoSent(false, !this.chatApi.connected);
    }

    _imageSent(success, isPending, imageSendingCompletion) {
        // Reset existing popups
        this.showImageUploadSuccess = false;
        this.showImageUploadFailure = false;

        if (success && !isPending) {
            this.showImageUploadSuccess = true;

            return this.$timeout(() => {
                this.showImageUploadSuccess = false;

                if (imageSendingCompletion) {
                    imageSendingCompletion();
                }
            }, MEDIA_COMPLETION_POPUP_TIMEOUT);
        }

        if (!isPending) {
            this.showImageUploadFailure = true;
        }

        this.$timeout(() => {
            this.showImageUploadFailure = false;

            if (imageSendingCompletion) {
                imageSendingCompletion();
            }
        }, MEDIA_COMPLETION_POPUP_TIMEOUT);
    }

    _videoSent(success, isPending) {
        // Reset existing popups
        this.showVideoUploadSuccess = false;
        this.showVideoUploadFailure = false;

        if (success && !isPending) {
            this.showVideoUploadSuccess = true;

            return this.$timeout(() => {
                this.showVideoUploadSuccess = false;
            }, MEDIA_COMPLETION_POPUP_TIMEOUT);
        }

        if (!isPending) {
            this.showVideoUploadFailure = true;
        }

        this.$timeout(() => {
            this.showVideoUploadFailure = false;
        }, MEDIA_COMPLETION_POPUP_TIMEOUT);
    }

    _getUploadConfirmation(url) {
        this.chatApi.sendLog(STATUS_MESSAGES.UPLOAD_CONFIRMATION_POP_UP, new Date().getTime());
        this.EventService.sendEventLog(
            'none',
            this.chatApi.roomId || 'none',
            STATUS_MESSAGES.UPLOAD_CONFIRMATION_POP_UP
        );

        return this.uploadConfirmationModal.show(url).then((result) => {
            const resultStatus = result
                ? STATUS_MESSAGES.UPLOAD_CONFIRMATION_OK
                : STATUS_MESSAGES.UPLOAD_CONFIRMATION_CANCEL;

            this.chatApi.sendLog(resultStatus, new Date().getTime());
            this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', resultStatus);

            return result;
        });
    }

    closePopups() {
        this.uploadConfirmationModal.close();

        if (!this.isInMainPage) {
            this._setCurrentPage(VIEW_PAGES.MAIN);
        }
    }

    isThumbnailMode() {
        return this.videoSettings.videoSize === this.VIDEO_SIZES.THUMBNAIL;
    }
}

import {TechseeFormBase} from '@techsee/techsee-ui-common/lib/forms/_shared/TechseeFormBase';
import {SimpleFieldModel, IFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';

export class SurveyForm extends TechseeFormBase {
    private readonly _translate: any;

    constructor(translate: any) {
        super();

        this._translate = translate;

        this.addField('answer', this.createAnswerField());
    }

    private createAnswerField(): IFieldModel {
        const field = new SimpleFieldModel({
            placeholder: this._translate.instant('REACT.END.VIEW.SURVEY_FORM_PLACEHOLDER')
        });

        return field;
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {MeetingVideoStreamControllerBase} from '../meeting.video-stream.controller.base';
import {KnownMediaStream, MediaStreamUnregisterReason} from '@techsee/techsee-media-service/lib/MediaConstants';
// @ts-ignore
import {STATUS_MESSAGES, VIEW_PAGES, CAMERA_ACCESS} from '../meeting.settings.js';
import {MeetingMode, UserType} from '@techsee/techsee-common/lib/constants/room.constants';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {StreamDestroyedEventArgs} from '@techsee/techsee-media-service/lib/MediaContracts';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {DEFAULT_BRANDING, IBrandingInfo} from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';
import {IAudioService} from '../AudioService';
import {IFeatureService} from '@techsee/techsee-client-infra/lib/services/FeatureService';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../../_react_/app.bootstrap';

export const LIVE_POINTER_LINKS = {
    MAC: LP_DOWNLOAD_URL_FOR_MAC || 'https://dslp-test.techsee.me/dslp-packages/osx/techsee_live_pointer.dmg',
    WINDOWS: LP_DOWNLOAD_URL_FOR_WINDOWS || 'https://dslp-test.techsee.me/dslp-packages/win/techsee_live_pointer.msi',
    LINUX: LP_DOWNLOAD_URL_FOR_LINUX || 'https://dslp-test.techsee.me/dslp-packages/linux/techsee_live_pointer.tar.gz'
};

function _isMediaMessage(message: {type: string}): boolean {
    return message.type === 'IMG-DB' || message.type === 'IMG-CL';
}

export class DesktopSharingController extends MeetingVideoStreamControllerBase {
    static $inject = [
        '$rootScope',
        '$scope',
        '$window',
        '$timeout',
        '$sessionStorage',
        '$sce',
        '$filter',
        'db',
        'tsChatHelper',
        'tsTermsAndConditions',
        'tsEventService',
        'preCameraPermissionService',
        'currentUser',
        'mobileAppMediaService',
        'tsFullscreenGalleryService',
        'sessionData',
        'branding',
        'audioService',
        'featureService'
    ];

    translations: {
        remoteControlStopRemoteControl: string;
        endSessionMessage: string;
        endSessionHeader: string;
        endSessionConfirm: string;
        endSessionCancel: string;
        agentContentTitle: string;
        livePointerDownload: string;
        code: string;

        title: string;
        titlePaused: string;
        pause: string;
        resume: string;
    };

    showEndSessionConfirmation = false;

    currentUser: any;

    db: any;

    sessionData: any;

    translate: any;

    tsPushNotificationService: any;

    featureService: IFeatureService;

    agentContent = '';

    previousMessage = '';

    tsEnvironmentDetect: any;

    branding: IBrandingInfo;

    constructor(
        $rootScope: any,
        $scope: any,
        $window: any,
        $timeout: any,
        $sessionStorage: any,
        $sce: any,
        $filter: any,
        db: any,
        tsChatHelper: any,
        tsTermsAndConditions: any,
        tsEventService: any,
        preCameraPermissionService: any,
        currentUser: any,
        mobileAppMediaService: any,
        tsFullscreenGalleryService: any,
        sessionData: any,
        branding: IBrandingInfo,
        audioService: IAudioService,
        featureService: IFeatureService
    ) {
        super(
            MeetingState.DesktopSharing,
            $rootScope,
            $scope,
            $sessionStorage,
            $timeout,
            $window,
            $sce,
            getRootStore().chatApi,
            tsChatHelper,
            tsTermsAndConditions,
            tsEventService,
            preCameraPermissionService,
            mobileAppMediaService,
            db,
            tsFullscreenGalleryService,
            audioService
        );

        const translate = $filter('translate');

        this.translations = {
            title: translate('MESSAGE.VIEW.DESKTOP_SHARING.TITLE_ACTIVE'),
            titlePaused: translate('MESSAGE.VIEW.DESKTOP_SHARING.TITLE_PAUSED'),
            pause: translate('MESSAGE.VIEW.DESKTOP_SHARING.PAUSE'),
            resume: translate('MESSAGE.VIEW.DESKTOP_SHARING.RESUME'),
            agentContentTitle: translate('MESSAGE.VIEW.DESKTOP_SHARING.AGENT_CONTENT_TITLE'),
            livePointerDownload: translate('MESSAGE.VIEW.DESKTOP_SHARING.LIVE_POINTER_DOWNLOAD'),
            code: '',
            endSessionHeader: translate('MESSAGE.VIEW.DESKTOP_SHARING.END_SESSION'),
            endSessionMessage: translate('MESSAGE.VIEW.DESKTOP_SHARING.END_SESSION_MESSAGE'),
            endSessionConfirm: translate('MESSAGE.VIEW.DESKTOP_SHARING.END_SESSION_CONFIRM'),
            endSessionCancel: translate('MESSAGE.VIEW.DESKTOP_SHARING.END_SESSION_CANCEL'),
            remoteControlStopRemoteControl: translate('MESSAGE.VIEW.DESKTOP_SHARING.REMOTE_CONTROL_STOP_REMOTE_CONTROL')
        };

        this.db = db;
        this.translate = translate;
        this.sessionData = sessionData;
        this.currentUser = currentUser;
        this.tsPushNotificationService = getRootStore().pushNotificationService;
        this.tsEnvironmentDetect = getRootStore().environmentDetect;
        this.featureService = featureService;

        this.continueSession = this.continueSession.bind(this);
        this.confirmEndSession = this.confirmEndSession.bind(this);

        this.$scope.$on('$viewContentLoaded', () => {
            // make the code execute on the next cycle
            this.$timeout(() => {
                this.sendEventLog(STATUS_MESSAGES.CLIENT_DESKTOP_SHARE_VIEW_LOADED, {
                    roomId: this.tsChatApi.roomId
                });
            }, 0);
        });

        this.onHistoryMessageReceived = this.onHistoryMessageReceived.bind(this);
        this.onStopRemoteControlMessageReceived = this.onStopRemoteControlMessageReceived.bind(this);
        this.stopRemoteControl = this.stopRemoteControl.bind(this);

        this.notifyConstructionIsReady();
        this.mobileAppMediaService.onStreamDestroyed((eventArgs: StreamDestroyedEventArgs) => {
            const {streamType, reason} = eventArgs;

            if (
                streamType === KnownMediaStream.USER_SCREEN_SHARE_STREAM &&
                reason === MediaStreamUnregisterReason.NativeEvent
            ) {
                this.tsChatHelper.finishMeeting();
            }
        });

        this.mobileAppMediaService.onDesktopSharingTypeSet(() => {
            const event = {
                key: 'desktopSharingStatus',
                value: {type: 'customerStartedDesktopSharing'},
                type: 'push'
            };

            this.setReportedField(this.tsChatApi.roomId, {data: {event}});
        });

        this.branding = this._getBranding(branding);
    }

    get currentRole(): any {
        return this.currentUser && this.currentUser.role;
    }

    get allowChat(): boolean {
        return !!get(this.tsChatApi, 'accountSettings.allowClientChat');
    }

    setStreamSource(): void {
        this.mobileAppMediaService.setDesktopStreamSource(this.tsChatApi.dashboardVideoResolution);
    }

    togglePauseResume(): void {
        const value = !this.isPausedByMobile ? 'desktopSharingSessionPaused' : 'desktopSharingSessionResumed';
        const event = {key: 'desktopSharingStatus', value, type: 'push'};

        this.togglePauseMobile();
        this.setReportedField(this.tsChatApi.roomId, {data: {event}});
    }

    meetingModeHandshakeSuccess(): void {
        this.tsChatHelper.setMode();
        this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SCREEN_HANDSHAKE_SUCCESS, true);

        this.tsChatApi.cameraApprovalDialogStateChange(false);
        this.tsChatApi.sendLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW);

        this.db.Rooms.find(this.tsChatApi.roomId).then((room: any) => {
            this.translations.code = this.translate('MESSAGE.VIEW.DESKTOP_SHARING.CODE', {
                code: room.livePointerCode
            });
        });

        const userId = get(this.currentUser, '_id');

        this.tsPushNotificationService
            .getPermission()
            .then(() => {
                this.sendEventLog(STATUS_MESSAGES.NOTIFICATION_PERMISSION_REQUESTED, {
                    userId,
                    roomId: this.tsChatApi.roomId,
                    meta: {
                        description: 'Notification permission was successfully requested',
                        origin: MeetingMode.screen
                    }
                });
            })
            .catch((error: any) => {
                this.sendEventLog(STATUS_MESSAGES.NOTIFICATION_PERMISSION_REQUEST_FAILED, {
                    userId,
                    roomId: this.tsChatApi.roomId,
                    meta: {
                        description: 'Notification permission requested was failed',
                        origin: MeetingMode.screen,
                        error
                    }
                });
            });

        this.sendEventLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW, {
            userId,
            roomId: this.tsChatApi.roomId,
            meta: {description: 'Customer approved desktop sharing access', origin: MeetingMode.screen}
        });

        this.setReportedField(this.tsChatApi.roomId, {
            data: {
                event: {
                    key: 'screenAccess',
                    value: CAMERA_ACCESS.CAMERA_APPROVED
                }
            }
        });

        this.setReportedField(this.tsChatApi.roomId, {
            data: {
                event: {
                    key: 'streamingIntent',
                    value: MeetingMode.screen,
                    type: 'push'
                }
            }
        });
    }

    syncVideoState(): void {
        return;
    }

    initVideoModeHandlers(): void {
        this.tsChatApi.on(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this.onHistoryMessageReceived);

        this.$scope.$on('$destroy', () => {
            this.tsChatApi.off(socketEvents.CLIENT_IN.NEW_HISTORY_MESSAGE_RECEIVED, this.onHistoryMessageReceived);
        });
    }

    onStopRemoteControlMessageReceived(triggeredByClient: boolean = false): void {
        this.tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AGENT_HAS_REMOTE_CONTROL, false);

        const userId = get(this.currentUser, '_id');
        const eventLogType = triggeredByClient
            ? STATUS_MESSAGES.CLIENT_DESKTOP_SHARE_STOP_REMOTE_CONTROL_SENT
            : STATUS_MESSAGES.CLIENT_DESKTOP_SHARE_STOP_REMOTE_CONTROL_RECEIVED;
        const description = triggeredByClient
            ? 'Customer desktop sharing stop remote control request sent'
            : 'Customer desktop sharing stop remote control request received';

        this.sendEventLog(eventLogType, {
            userId,
            roomId: this.tsChatApi.roomId,
            meta: {
                description: description,
                origin: MeetingMode.desktopSharing
            }
        });
    }

    get isUnderRemoteControl() {
        return this.tsChatApi.client.agentHasRemoteControl;
    }

    stopRemoteControl(): void {
        this.onStopRemoteControlMessageReceived(true);
    }

    get loopbackStreamType(): KnownMediaStream {
        return KnownMediaStream.USER_SCREEN_SHARE_STREAM;
    }

    downloadLivePointer(): void {
        this.setReportedField(this.tsChatApi.roomId, {
            data: {
                event: {
                    key: 'livePointerDownloadsCount',
                    value: 1,
                    type: 'inc'
                }
            }
        });

        this.setReportedField(this.tsChatApi.roomId, {
            data: {
                event: {
                    key: 'desktopSharingStatus',
                    value: 'downloadLivePointerClicked',
                    type: 'push'
                }
            }
        });

        let downloadLink = '';

        if (this.tsEnvironmentDetect.isLinux()) {
            downloadLink = LIVE_POINTER_LINKS.LINUX;
        }

        if (this.tsEnvironmentDetect.isMac()) {
            downloadLink = LIVE_POINTER_LINKS.MAC;
        }

        if (this.tsEnvironmentDetect.isWindows()) {
            downloadLink = LIVE_POINTER_LINKS.WINDOWS;
        }

        if (downloadLink) {
            const link = document.createElement('a');

            link.download = 'live-pointer';
            link.href = downloadLink;
            link.click();
        }
    }

    endMeeting(): void {
        this.showEndSessionConfirmation = true;
    }

    continueSession(): void {
        this.showEndSessionConfirmation = false;

        this.tsChatApi.sendLog(STATUS_MESSAGES.END_MEETING_CANCELLED, new Date().getTime());
        this.sendEventLog(STATUS_MESSAGES.END_MEETING_CANCELLED, {
            roomId: this.tsChatApi.roomId,
            meta: 'Customer selected to continue the session'
        });
    }

    confirmEndSession(): void {
        this.showEndSessionConfirmation = false;
        this.tsChatHelper.finishMeeting();
    }

    displayExpandButton(): boolean {
        return this.tsChatApi.connected && this.tsChatHelper.isInSpeedtestPage;
    }

    closeGallery(): void {
        this.tsChatHelper.setPage(VIEW_PAGES.MAIN);
    }

    onHistoryMessageReceived(messageData: any): void {
        const {message} = messageData;

        if (get(message, 'sender') === UserType.client) {
            return;
        }

        if (message.data.type === 'image') {
            if (this.previousMessage !== message.data.message) {
                const browserLocale = navigator.language.split('-')[0];
                const notificationMessage =
                    browserLocale.toLowerCase() === 'fr'
                        ? "Une image vous a été envoyée par l'agent"
                        : 'Image from support agent is waiting for you';

                this.tsPushNotificationService.notify(notificationMessage);
            }
            this.previousMessage = message.data.message;

            this.agentContent = message.data.message;
        }
    }

    selectedChatImage(messageData: any): void {
        if (_isMediaMessage(messageData) || !messageData.isVideo) {
            this.agentContent = messageData.data;
        }
    }

    private _getImage(path: string) {
        try {
            return this.$rootScope.requireImage(path);
        } catch (e) {
            return '';
        }
    }

    private _getDefaultBranding(): IBrandingInfo {
        return {
            ...DEFAULT_BRANDING,
            logo: this._getImage('desktop-sharing/techsee.png')
        };
    }

    private _getBranding(branding: IBrandingInfo): IBrandingInfo {
        const defaultBranding = this._getDefaultBranding();

        return isEmpty(branding)
            ? defaultBranding
            : {
                  ...defaultBranding,
                  ...branding,
                  logo: this._getImage(`desktop-sharing/${branding.logo}.png`)
              };
    }
}

import '@techsee/techsee-ui-common/lib/_shared/icons.css';
import React from 'react';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
// @ts-ignore
import EndView from './end.view.html';
import {EndStores, IEndStores} from './end.stores';
import Layout from './components/layout';

declare const angular: any;

//Configures MobX to allow changing state from actions only.
configure({
    enforceActions: 'observed'
});

export interface IEndRootComponent {
    stores: IEndStores;
}

export class EndRootComponent extends React.Component<IEndRootComponent> {
    render() {
        const {stores} = this.props;

        if (typeof stores !== 'object') {
            return '';
        }

        const providerStores: any = {};

        Object.getOwnPropertyNames(stores).forEach((storeName) => {
            providerStores[storeName] = stores[storeName];
        });

        return (
            <Provider {...providerStores}>
                <Layout />
            </Provider>
        );
    }
}

const reactDirectiveWrapper = ['reactDirective', (reactDirective: any) => reactDirective(EndRootComponent)];

export default angular
    .module('states.endNew', [])
    .directive('endRootComponent', reactDirectiveWrapper)
    .config(['$stateProvider', config]);

function config($stateProvider: any) {
    $stateProvider.state('endNew', {
        url: BASE_PATH + 'endNew?offlineRoom&csi&expiredRoom&webRtcSupported',
        template: EndView,
        controller: EndStores,
        controllerAs: 'end',
        onEnter: [
            'tsSoloService',
            (tsSoloService: any) => {
                'ngInject';

                if (!tsSoloService.isSolo) {
                    return;
                }

                setTimeout(() => {
                    tsSoloService.getAbsUrlAfterEndMeeting().then((url: any) => {
                        if (url) {
                            window.location.href = url;
                        }
                    });
                }, 2000);
            }
        ]
    });
}

'use strict';

import forEach from 'lodash/forEach';
import {getRootStore} from '../../app.bootstrap';

export interface ITsAppstoreUrlUtilsService {
    isAppstoreLink(url: string): boolean;
    prepareAppstoreRedirect(): void;
}

export class TsAppstoreUrlUtilsService {
    private chatApi: any;

    constructor() {
        this.chatApi = getRootStore().chatApi;
    }

    /**
     * Returns true if url is an appstore link
     */
    isAppstoreLink(url: string): boolean {
        let appstoreLink = false;
        const appStoreURLsArray = String(APP_STORE_URLS).split(',');

        forEach(appStoreURLsArray, (appStoreURL) => {
            const urlPattern = new RegExp(decodeURI(appStoreURL));

            if (urlPattern.test(url)) {
                appstoreLink = true;
            }
        });

        return appstoreLink;
    }

    prepareAppstoreRedirect(): void {
        const params: {roomId: string; userUniqueId: string} = {
            roomId: this.chatApi.roomId,
            userUniqueId: this.chatApi.getCurrentUserUniqueId()
        };

        const query = Object.keys(params)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent((params as Record<string, string>)[k])}`)
            .join('&');

        // Set cookie expires date to one hour ahead
        const expires = new Date(Date.now() + 1000 * 60 * 60).toUTCString();

        document.cookie = `roomLink=https://${window.location.host}/?${query}; domain=.techsee.me; path=/; expires=${expires}`;
    }
}

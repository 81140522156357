'use strict';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import './terms-notice.style.scss';
import {STATUS_MESSAGES} from '../../meeting.settings.js';
import {ExternalPackageType} from '@techsee/techsee-common/lib/constants/account.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../../../_react_/app.bootstrap';

export class TermsNoticeController {
    constructor($translate, $rootScope, $uibModalInstance, termsInfo, termsTranslationStrings, db, tsEventService) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.db = db;
        this.$uibModalInstance = $uibModalInstance;
        this.termsURL = termsInfo.termsURL;
        this.accountTermsUrl = termsInfo.accountTermsUrl;
        this.privacyURL = termsInfo.privacyURL;
        this.companyName = termsInfo.companyName;
        this.termsTranslationStrings = termsTranslationStrings;
        this.EventService = tsEventService;
        this.allowTermsMessage = termsInfo.allowTermsMessage;
        this.customCancelMessage = termsInfo.customCancelMessage;
        this.LOCALE_DIR = $rootScope.LOCALE_DIR;
        this.translate = $translate;
        this.accountType = this.chatApi.accountSettings.accountType;
        const supportedPackageTypes = [ExternalPackageType.VRA];

        this.isPackageSupportedType = includes(supportedPackageTypes, this.accountType);

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, true);

        if (this.chatApi.accountSettings.enableNewInvite) {
            if (this.chatApi.dashboard.isTosRepromptSent) {
                this.tosRepromptDone();
            } else if (this.chatApi.client.tosRejected && this.chatApi.client.isReviewingTOS) {
                this.waitingForTosReprompt();
            }
        }

        this.chatApi.onReady(() => {
            this.openTermsLinksOnNewWindow = this.chatApi.accountSettings.openTermsLinksOnNewWindow;
        });

        this.ok = this.ok.bind(this);
        this.cancel = this.cancel.bind(this);

        this.useCustomTermsMessage = !isEmpty(termsTranslationStrings);

        this.termsAllowButtonAria = this.translate.instant('TERMS.VIEW.ALLOW_BUTTON_ARIA');

        this.termsCancelButtonAria = this.translate.instant('TERMS.VIEW.CANCEL_BUTTON_ARIA');

        if (this.useCustomTermsMessage) {
            forEach(this.termsTranslationStrings.termsMessageParts, (part) => {
                switch (part.type) {
                    case 'TERMS':
                        part.url = termsInfo.termsURL;
                        break;
                    case 'PRIVACY':
                        part.url = termsInfo.privacyURL;
                        break;
                    case 'ACCOUNT':
                        part.url = termsInfo.accountTermsUrl;
                }
            });
        }
    }

    ok() {
        const {TERMS_ACCEPTED, TOS_ACCEPTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_ACCEPTED, true);

        this.chatApi.sendLog(TOS_ACCEPTED);
        this.db.Rooms.setReportedField(this.chatApi.roomId, {data: {event: {key: 'tosAccepted', value: true}}});
        this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', TERMS_ACCEPTED);
        this.$uibModalInstance.close();
    }

    cancel() {
        if (this.chatApi.accountSettings.enableNewInvite) {
            this.newInviteTosRejectedFlow();
        } else {
            this.oldInviteTosRejectedFlow();
        }
    }

    waitingForTosReprompt() {
        this.isWaitingForAgentToAskReprompt = true;
        this.chatApi.once(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_IS_TOS_REPROMPT_SENT,
            this.tosRepromptDone.bind(this)
        );
    }

    tosRepromptDone() {
        this.isWaitingForAgentToAskReprompt = false;
        this.db.Rooms.setReportedField(this.chatApi.roomId, {
            data: {event: {key: 'tosRepromptDone', value: true}}
        });
    }

    newInviteTosRejectedFlow() {
        const {TERMS_REJECTED, TOS_REJECTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED, true);
        this.chatApi.sendLog(TOS_REJECTED);

        if (this.chatApi.dashboard.isTosRepromptSent) {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);
            this.db.Rooms.setReportedField(this.chatApi.roomId, {
                data: {event: {key: 'tosAccepted', value: false}}
            });

            setTimeout(() => this.$uibModalInstance.dismiss());
        } else {
            this.waitingForTosReprompt();
        }

        this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', TERMS_REJECTED);
    }

    oldInviteTosRejectedFlow() {
        const {TERMS_REJECTED, TOS_REJECTED} = STATUS_MESSAGES;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED, true);
        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);
        this.chatApi.sendLog(TOS_REJECTED);
        this.db.Rooms.setReportedField(this.chatApi.roomId, {data: {event: {key: 'tosAccepted', value: false}}});
        this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', TERMS_REJECTED);
        this.$uibModalInstance.dismiss();
    }

    termsToggle(visible) {
        this.termsWindowOpen = visible;
    }
}

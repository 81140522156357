'use strict';
import get from 'lodash/get';
import {getAuthService} from '@techsee/techsee-client-infra/lib';
import {SimpleApiService} from '@techsee/techsee-client-infra/lib/services/SimpleApiService';

export class AuthService {
    constructor($localStorage, $sessionStorage, db, tsUrlConfig) {
        'ngInject';

        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.db = db;

        const apiUrl = tsUrlConfig.get('API_URL');

        this._auth = getAuthService(apiUrl);
        this._simpleApiService = new SimpleApiService(apiUrl);

        this.loginWithOneTimeToken = this.loginWithOneTimeToken.bind(this);
        this.loginWithOneTimeTokenAsRegularUser = this.loginWithOneTimeTokenAsRegularUser.bind(this);
        this.getAuthToken = this.getAuthToken.bind(this);
        this.setAuthToken = this.setAuthToken.bind(this);
    }

    /**
     * Make a login request
     *
     * @param  {string} userName     Username
     * @param  {string} password     User password
     * @return {Object}              The request Promise
     */
    login(userName, password, keepLogged) {
        const loginData = LoginEncoder.encode(userName, password);

        return this.db.Token.auth({
            data: {
                loginData: loginData
            },
            longTermToken: true
        }).then((res) => {
            // Login successful, save the Token
            this.setAuthToken(res.data, keepLogged);

            return res.data;
        });
    }

    loginWithOneTimeToken(ott, keepLogged = true) {
        if (!ott) {
            return Promise.resolve({isRedeemed: false});
        }

        return this._auth.redeemOneTimeToken(ott).then((token) => {
            if (token) {
                // Login successful, save the Token
                this.setAuthToken({token}, keepLogged);

                return {isRedeemed: true};
            }

            return {isRedeemed: false};
        });
    }

    loginWithOneTimeTokenAsRegularUser(ott, keepLogged = true) {
        if (!ott) {
            return Promise.resolve({isRedeemed: false});
        }

        return this._simpleApiService.redeemOneTimeToken(ott).then((result) => {
            if (result && result.token) {
                // Login successful, save the Token
                this.setAuthToken({token: result.token, simpleToken: result.simpleToken}, keepLogged);

                return {isRedeemed: true};
            }

            return {isRedeemed: false};
        });
    }

    createOneTimeToken() {
        return this._auth.createOneTimeToken(this.getAuthToken());
    }

    /**
     * Request to send email with link that will log in user
     *
     * @param {String} email user email
     * @returns {Promise} The request Promise
     */
    createMagicLink(email, keepLogged) {
        return this.db.Token.magicLink({
            data: {
                email,
                keepLogged
            }
        });
    }

    useMagicLinkToken(ott) {
        return this.db.OTT.redeem({data: {ott}}).then((res) => {
            const {token, keepLogged} = res.data;

            this.setAuthToken(token, keepLogged);

            return res.data;
        });
    }

    getAuthToken() {
        return get(this.$sessionStorage, 'auth.token') || get(this.$localStorage, 'auth.token');
    }

    setAuthToken(token, keepLogged = true) {
        if (keepLogged) {
            this.$localStorage.auth = token;
        } else {
            this.$sessionStorage.auth = token;
        }
    }

    /**
     * Sign the use out of the system and cleanup the saved Token
     *
     * @param {String} accountId accountId of current user
     * @param {String} userId userId of current user
     * @return {Object} The request Promise
     */
    logout(accountId, userId) {
        return this.db.Auth.logout({data: {accountId, userId}}).then((res) => {
            // Logout successful, clear the saved Token
            this.$localStorage.auth = null;
            this.$sessionStorage.auth = null;
            this.$localStorage.$apply();
            this.$sessionStorage.$apply();

            return res;
        });
    }
}

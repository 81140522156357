'use strict';

export class DbService {
    constructor(DS, DSHttpAdapter, tsUrlConfig) {
        'ngInject';

        this.urlConfig = tsUrlConfig;

        // API_URL global variable set in webpack.config.js
        const baseRoot = ENV.local ? '/' : this.urlConfig.get('API_URL');
        const statsApiUrl = STATS_API_URL ? this.urlConfig.get('STATS_API_URL') : baseRoot;

        // Expose the DS object
        this.DS = DS;

        DS.defaults.basePath = baseRoot + 'api';

        if (ENV.production) {
            // Disable console logs
            DSHttpAdapter.defaults.log = false;
        }

        this.Auth = DS.defineResource({
            name: 'auth',
            basePath: baseRoot,
            endpoint: '/',
            actions: {
                logout: {
                    method: 'POST'
                }
            }
        });

        this.Token = DS.defineResource({
            name: 'token',
            basePath: baseRoot,
            endpoint: '/api',
            actions: {
                auth: {
                    method: 'POST'
                },
                magicLink: {
                    endpoint: '/api/auth',
                    method: 'POST'
                }
            }
        });

        this.User = DS.defineResource({
            name: 'users',
            idAttribute: '_id',
            actions: {
                byAccount: {
                    method: 'GET'
                },
                byAccountRole: {
                    method: 'GET'
                },
                byAccountGroupManager: {
                    method: 'GET'
                },
                isUserNameAvailable: {
                    method: 'GET'
                },
                groupPeers: {
                    method: 'GET'
                }
            }
        });

        this.Account = DS.defineResource({
            name: 'accounts',
            idAttribute: '_id',
            actions: {
                clientSessionInitiation: {
                    method: 'GET'
                },
                termsTranslationStrings: {
                    method: 'GET'
                },
                getBrandingRedirectSettings: {
                    method: 'GET'
                }
            }
        });

        this.Group = DS.defineResource({
            name: 'groups',
            idAttribute: '_id',
            relations: {
                belongsTo: {
                    accounts: {
                        localField: 'accounts',
                        localKey: 'accountId',
                        parent: true
                    }
                }
            },
            actions: {
                byManager: {
                    method: 'GET'
                }
            }
        });

        this.Reports = DS.defineResource({
            name: 'reports',
            endpoint: 'reports/accounts',
            idAttribute: '_id',
            actions: {
                meetings: {
                    method: 'GET'
                },
                groupReports: {
                    method: 'GET'
                },
                userReports: {
                    method: 'GET'
                }
            }
        });

        this.Rooms = DS.defineResource({
            name: 'rooms',
            idAttribute: '_id',
            actions: {
                active: {
                    method: 'GET'
                },
                upload: {
                    method: 'POST'
                },
                sentUrl: {
                    method: 'GET'
                },
                setReportedField: {
                    method: 'PUT'
                },
                setSpeedtestResults: {
                    method: 'PUT'
                },
                isSuccessfulSession: {
                    method: 'GET'
                },
                clientConnected: {
                    method: 'GET'
                },
                endSessionReturnUrl: {
                    method: 'GET'
                }
            },
            methods: {
                changeAgent: function (type) {
                    return this.DSUpdate(
                        {agent: type},
                        {
                            endpoint: 'rooms/agent'
                        }
                    );
                },

                setDeviceInfo: function (info, isNative, clientUsingFs, clientType, usingApplication) {
                    return this.DSUpdate(
                        {
                            info: info,
                            isNative: isNative,
                            clientUsingFs: clientUsingFs,
                            clientType: clientType,
                            usingApplication: usingApplication
                        },
                        {
                            endpoint: 'rooms/deviceInfo'
                        }
                    );
                },

                setNetworkInfo: function (connectionType, downlinkMax) {
                    return this.DSUpdate(
                        {
                            connectionType: connectionType,
                            downlinkMax: downlinkMax
                        },
                        {
                            endpoint: 'rooms/networkInfo'
                        }
                    );
                },

                setCustomerReference: function (customerReference) {
                    return this.DSUpdate(
                        {
                            customerId: customerReference
                        },
                        {
                            endpoint: 'rooms/referenceInfo'
                        }
                    );
                },

                end: function () {
                    return this.DSUpdate(
                        {
                            closedBy: 'MOBILE'
                        },
                        {
                            endpoint: 'rooms/end'
                        }
                    );
                }
            }
        });

        this.History = DS.defineResource({
            name: 'history',
            endpoint: 'history',
            idAttribute: '_id',
            actions: {
                byCustomerIdOrNumber: {
                    method: 'GET'
                }
            }
        });

        this.Shorturl = DS.defineResource({
            name: 'shorturl',
            idAttribute: 'url'
        });

        this.EventLog = DS.defineResource({
            basePath: statsApiUrl,
            name: 'eventLog',
            endpoint: '/api/eventLog',
            idAttribute: '_id',
            deserialize: () => ({_id: ''})
        });

        this.Device = DS.defineResource({
            name: 'device',
            actions: {
                checkSupport: {
                    method: 'GET'
                }
            }
        });

        this.CustomStrings = DS.defineResource({
            name: 'customStrings',
            endpoint: 'customStrings',
            idAttribute: '_id',
            actions: {
                byAccount: {
                    method: 'GET'
                }
            }
        });

        this.OTT = DS.defineResource({
            name: 'ott',
            endpoint: 'ott',
            actions: {
                redeem: {
                    method: 'POST'
                }
            }
        });

        this.CustomerSurveys = DS.defineResource({
            name: 'customerSurveys',
            endpoint: 'customerSurveys',
            actions: {
                rating: {
                    method: 'POST'
                },
                answers: {
                    method: 'PUT'
                },
                questions: {
                    method: 'GET'
                }
            }
        });

        this.AccountBranding = DS.defineResource({
            name: 'accountBrandingBySubdomain',
            idAttribute: '_id',
            actions: {
                getAccountBrandingBySubdomain: {
                    method: 'GET',
                    pathname: '/'
                }
            }
        });
    }
}

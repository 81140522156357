'use strict';

/**
 * Add auth token headers to each request
 * Expects the token to be saved in LocalStorage
 */

export function TokenInterceptor($localStorage, $sessionStorage, $injector, tsUrlConfig) {
    'ngInject';
    function request(config) {
        const url = config.url;

        if (url.indexOf('http') === 0 && url.indexOf(tsUrlConfig.get('API_URL')) < 0) {
            // Prevent headers modifications for request to 3rd party services
            return config;
        }

        const token =
            ($sessionStorage.auth && $sessionStorage.auth.token) || ($localStorage.auth && $localStorage.auth.token);

        if (token) {
            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + token;
        }

        return config;
    }

    function responseError(config) {
        if (config.status === 401) {
            const tsStateHelper = $injector.get('tsStateHelper');

            if (tsStateHelper.$state.current.name !== 'fs') {
                tsStateHelper.safeGo('start.main');
            }
        }

        return config;
    }

    return {
        request: request,
        responseError: responseError
    };
}

'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';

export class MagicLinkController {
    constructor(auth, $translate, loginToken) {
        'ngInject';

        this.auth = auth;
        this.$translate = $translate;
        this.keepLogged = getRootStore().urlUtils.getParamValue('keepLogged');

        if (loginToken.error) {
            this.$translate('MAGIC_LINK.VIEW.ERROR').then((message) => (this.loginMessage = message));
        }

        this.DASHBOARD_URL = TsUtils.replaceUrlDomain(DASHBOARD_URL, window.location.hostname);
    }

    _errorMessage(err) {
        if (err.status === 400 || err.status === 401) {
            this.$translate('FS.CONTROLLER.WRONG_EMAIL').then((message) => (this.loginMessage = message));

            return;
        }

        if (err.status === 403 || err.status === 404) {
            this.$translate('FS.CONTROLLER.NOT_ALLOWED').then((message) => (this.loginMessage = message));

            return;
        }

        if (err.status === 500 || err.status === 502 || err.status === -1) {
            this.$translate('FS.CONTROLLER.BAD_GATEWAY').then((message) => (this.loginMessage = message));
        }
    }

    submit(form) {
        if (!form.email) {
            // Please enter email to continue
            this.$translate('FS.CONTROLLER.ENTER_EMAIL').then((message) => (this.loginMessage = message));

            return;
        }

        return this.auth
            .createMagicLink(form.email, this.keepLogged)
            .then(() => {
                return this.$translate('FS.CONTROLLER.MAGIC_LINK_SENT').then(
                    (message) => (this.loginMessage = message)
                );
            })
            .catch((err) => {
                this._errorMessage(err, true);
            });
    }
}

'use strict';

import get from 'lodash/get';

import {STATUS_MESSAGES} from '../../states/meeting/meeting.settings';
import {getRootStore} from '../../_react_/app.bootstrap';

export interface ITsEndMeetingModalService {
    init(styleButton: string): void;
    pop(): Promise<void>;
}

export class TsEndMeetingModalService {
    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['EndMeetingConfirmationModal', '$stateParams', 'tsChatHelper', 'tsEventService'];

    private chatApi: any;
    private EndMeetingConfirmationModal: any;
    private endParams: {csi?: string};
    private chatHelper: any;
    private EventService: any;
    private styleButton = '';

    constructor(EndMeetingConfirmationModal: any, $stateParams: any, tsChatHelper: any, tsEventService: any) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.EndMeetingConfirmationModal = EndMeetingConfirmationModal;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.chatHelper = tsChatHelper;
        this.EventService = tsEventService;
    }

    init(styleButton: string) {
        this.styleButton = styleButton;
    }

    pop() {
        this.chatApi.sendLog(STATUS_MESSAGES.END_MEETING_POP_UP, new Date().getTime());
        this.EventService.sendEventLog('none', this.chatApi.roomId || 'none', STATUS_MESSAGES.END_MEETING_POP_UP);

        const requestEndMeetingConfirmation = get(this.chatApi, 'accountSettings.requestEndMeetingConfirmation');

        const promise =
            (requestEndMeetingConfirmation && this.EndMeetingConfirmationModal.show(this.styleButton)) ||
            Promise.resolve();

        return promise.then(() => this.chatHelper.endMeeting(this.endParams, this.styleButton));
    }
}

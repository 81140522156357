import {AppRootStore, IAppRootStore} from './app.root.store';

let rootStore: IAppRootStore;

export async function reactAppBootstrap(): Promise<IAppRootStore> {
    rootStore = new AppRootStore();

    await rootStore.initRootServices();

    return rootStore;
}

export function getRootStore(): IAppRootStore {
    if (!rootStore) {
        throw 'root store was not initialized, insure you have bootstrapped the application';
    }

    return rootStore;
}

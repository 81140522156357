'use strict';

import mlView from './magic-link.view.html';
import {MagicLinkController} from './magic-link.controller';
import './magic-link.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

function config($stateProvider) {
    $stateProvider.state('magicLink', {
        url: BASE_PATH + 'magicLink?&ott&keepLogged',
        template: mlView,
        controller: 'MagicLinkController',
        controllerAs: 'ml',
        resolve: {
            loginToken: (tsStateHelper, db, auth) => {
                const ott = getRootStore().urlUtils.getParamValue('ott');

                if (!ott) {
                    return Promise.resolve({});
                }

                return auth
                    .useMagicLinkToken(ott)
                    .then(() => {
                        tsStateHelper.safeGo('fs');

                        return {};
                    })
                    .catch(() => ({error: true}));
            }
        }
    });
}

export default angular
    .module('states.magicLink', [])
    .config(config)
    .controller('MagicLinkController', MagicLinkController);

'use strict';

import {AuthService} from './auth/auth.service';
import {DbService} from './db/db.service';
import {TsStateHelperService} from './ts-state-helper/ts-state-helper.service';
import {TokenInterceptor} from './token-interceptor/token-interceptor.service';
import {TsExceptionHandler} from './ts-exception-handler/ts-exception-handler.service';
import {CustomTranslateLoader} from './custom-translate-loader/custom-translate-loader.service';
import {TsTermsAndConditions} from './ts-terms-and-condition/ts-terms-and-condition.service';
import {TsChatHelper} from './ts-chat-helper/ts-chat-helper.service';
import {TsFlashlightHelper} from './ts-flashlight-helper/ts-flashlight-helper.service';
import {TsEventService} from './ts-event-service/ts-event-service';
import {TsCobrowsingService} from './ts-cobrowsing/ts-cobrowsing-service.service';
import {TsEndMeetingModalService} from './ts-end-meeting/ts-end-meeting-modal.service';
import {TsTestUpload} from './ts-test-upload/ts-test-upload.service';
import {TsSoloService} from './ts-solo/ts-solo.service';

export default angular
    .module('app.services', ['tsUrlConfig.service'])
    .service('auth', AuthService)
    .service('db', DbService)
    .service('tsStateHelper', TsStateHelperService)
    .service('tokenInterceptor', TokenInterceptor)
    .service('tsExceptionHandler', TsExceptionHandler)
    .service('customTranslateLoader', CustomTranslateLoader)
    .service('tsTermsAndConditions', TsTermsAndConditions)
    .service('tsChatHelper', TsChatHelper)
    .service('tsFlashlightHelper', TsFlashlightHelper)
    .service('tsEventService', TsEventService)
    .service('tsCobrowsingService', TsCobrowsingService)
    .service('tsEndMeetingModalService', TsEndMeetingModalService)
    .service('tsTestUpload', TsTestUpload)
    .service('tsSoloService', TsSoloService);

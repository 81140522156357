import {BrandingService, IBrandingService} from './services/ts-branding-service/ts-branding-service';
import {
    BrowserUtilsService,
    IBrowserUtilsService
} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {
    AccountBrandingService,
    IAccountBrandingService
} from '@techsee/techsee-client-infra/lib/services/AccountBrandingService';
// @ts-ignore
import {getBackendUrl} from '@techsee/techsee-common/lib/utils';
import {ITsEnvironmentDetect, TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {IUrlUtilsService, TsUrlUtilsService} from './services/ts-url-utils/ts-url-utils.service';
import {
    ITsPushNotificationService,
    TsPushNotificationService
} from './services/ts-push-notification/ts-push-notification.service';
import {DeviceService, IDeviceService} from '@techsee/techsee-client-infra/lib/services/DeviceService';
import {ITsBrowserDetect, TsBrowserDetectService} from './services/ts-browser-detect/ts-browser-detect.service';
import {IVisibilityChange, TsVisibilityChange} from './services/ts-visibility-change/ts-visibility-change.service';
import {
    DETECT_RTC_ADVISED_TIMEOUT,
    DetectWebRtcService,
    IWebRtcDetector
} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {IRestApiService, RestApiService} from '@techsee/techsee-client-infra/lib/services/RestApiService';
import {MobileChatApiService} from './services/ts-chat-api/MobileChatApi';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {
    ITsAppstoreUrlUtilsService,
    TsAppstoreUrlUtilsService
} from './services/ts-appstore-url-utils/ts-appstore-url-utils.service';
import {ITsNetworkInfo, TsNetworkInfo} from './services/ts-network-info/ts-network-info.service';
import {DefaultRoomChannelTracer} from '@techsee/techsee-client-infra/lib/infra/RoomChannelTracer';
import {IRoomChannelTracer} from '@techsee/techsee-client-infra/lib/infra/RoomChannelContracts';
import {CustomStringService, ICustomStringService} from '@techsee/techsee-client-infra/lib/services/CustomString';
import {
    ITsTranslationHelper,
    TsTranslationHelper
} from './services/ts-translation-helper/ts-translation-helper.service';
import {ITsImageFixerService, TsImageFixerService} from './services/ts-image-fixer/ts-image-fixer.service';
import {TsVideoFixerService, ITsVideoFixerServiceInterface} from './services/ts-video-fixer/ts-video-fixer.service';

export interface IAppRootStore {
    initRootServices(): Promise<void>;

    readonly brandingService: IBrandingService;
    readonly browserUtilsService: IBrowserUtilsService;
    readonly environmentDetect: ITsEnvironmentDetect;
    readonly urlUtils: IUrlUtilsService;
    readonly pushNotificationService: ITsPushNotificationService;
    readonly browserDetect: ITsBrowserDetect;
    readonly visibilityChange: IVisibilityChange;
    readonly webRtcDetector: IWebRtcDetector;
    readonly restApiService: IRestApiService;
    readonly chatApi: MobileChatApiService;
    readonly appstoreUrlUtils: ITsAppstoreUrlUtilsService;
    readonly networkInfo: ITsNetworkInfo;
    readonly roomChannelTracer: IRoomChannelTracer;
    readonly translationHelper: ITsTranslationHelper;
    readonly imageFixer: ITsImageFixerService;
    readonly videoFixer: ITsVideoFixerServiceInterface;
}

const apiUrl = getBackendUrl(API_URL, {hostname: window.location.hostname, ENV: {cordova: false}});

export class AppRootStore implements IAppRootStore {
    private _brandingService?: IBrandingService;
    private _browserUtilsService?: IBrowserUtilsService;
    private _accountBrandingApiService?: IAccountBrandingService;
    private _restApiService?: IRestApiService;
    private _customStringApiService?: ICustomStringService;
    private _deviceApiService?: IDeviceService;
    private _environmentDetect?: ITsEnvironmentDetect;
    private _urlUtils?: IUrlUtilsService;
    private _pushNotificationService?: TsPushNotificationService;
    private _browserDetect?: ITsBrowserDetect;
    private _visibilityChange?: IVisibilityChange;
    private _webRtcDetector?: IWebRtcDetector;
    private _chatApi?: MobileChatApiService;
    private _appstoreUrlUtils?: ITsAppstoreUrlUtilsService;
    private _networkInfo?: ITsNetworkInfo;
    private _roomChannelTracer?: IRoomChannelTracer;
    private _translationHelper?: ITsTranslationHelper;
    private _imageFixer?: ITsImageFixerService;
    private _videoFixer?: ITsVideoFixerServiceInterface;

    constructor() {}

    async initRootServices(): Promise<void> {
        this._browserUtilsService = new BrowserUtilsService(window);
        this._accountBrandingApiService = new AccountBrandingService(apiUrl);
        this._deviceApiService = new DeviceService(apiUrl);
        this._customStringApiService = new CustomStringService(apiUrl);
        this._brandingService = new BrandingService(this._accountBrandingApiService, this._browserUtilsService);
        this._environmentDetect = await TsEnvironmentDetect.create();
        this._urlUtils = new TsUrlUtilsService();
        this._pushNotificationService = new TsPushNotificationService(window);
        this._browserDetect = new TsBrowserDetectService(this._deviceApiService);
        this._visibilityChange = new TsVisibilityChange();
        this._webRtcDetector = new DetectWebRtcService(DETECT_RTC_ADVISED_TIMEOUT);
        this._restApiService = new RestApiService(apiUrl);
        this._chatApi = new MobileChatApiService(PlatformType.mobile_web);
        this._appstoreUrlUtils = new TsAppstoreUrlUtilsService();
        this._networkInfo = new TsNetworkInfo();
        this._roomChannelTracer = new DefaultRoomChannelTracer();
        this._translationHelper = new TsTranslationHelper(this._customStringApiService);
        this._imageFixer = new TsImageFixerService();
        this._videoFixer = new TsVideoFixerService();
    }

    get chatApi(): MobileChatApiService {
        if (!this._chatApi) {
            throw new Error('chatApi not initialized');
        }

        return this._chatApi;
    }

    get videoFixer(): ITsVideoFixerServiceInterface {
        if (!this._videoFixer) {
            throw new Error('videoFixer not initialized');
        }

        return this._videoFixer;
    }

    get imageFixer(): ITsImageFixerService {
        if (!this._imageFixer) {
            throw new Error('imageFixer not initialized');
        }

        return this._imageFixer;
    }

    get translationHelper(): ITsTranslationHelper {
        if (!this._translationHelper) {
            throw new Error('translationHelper not initialized');
        }

        return this._translationHelper;
    }

    get roomChannelTracer(): IRoomChannelTracer {
        if (!this._roomChannelTracer) {
            throw new Error('roomChannelTracer not initialized');
        }

        return this._roomChannelTracer;
    }

    get networkInfo(): ITsNetworkInfo {
        if (!this._networkInfo) {
            throw new Error('networkInfo not initialized');
        }

        return this._networkInfo;
    }

    get appstoreUrlUtils(): ITsAppstoreUrlUtilsService {
        if (!this._appstoreUrlUtils) {
            throw new Error('appstoreUrlUtils not initialized');
        }

        return this._appstoreUrlUtils;
    }

    get restApiService(): IRestApiService {
        if (!this._restApiService) {
            throw new Error('restApiService not initialized');
        }

        return this._restApiService;
    }

    get brandingService(): IBrandingService {
        if (!this._brandingService) {
            throw new Error('brandingService not initialized');
        }

        return this._brandingService;
    }

    get webRtcDetector(): IWebRtcDetector {
        if (!this._webRtcDetector) {
            throw new Error('webRtcDetector not initialized');
        }

        return this._webRtcDetector;
    }

    get visibilityChange(): IVisibilityChange {
        if (!this._visibilityChange) {
            throw new Error('visibilityChange not initialized');
        }

        return this._visibilityChange;
    }

    get browserDetect(): ITsBrowserDetect {
        if (!this._browserDetect) {
            throw new Error('browserDetect not initialized');
        }

        return this._browserDetect;
    }

    get urlUtils(): IUrlUtilsService {
        if (!this._urlUtils) {
            throw new Error('urlUtils not initialized');
        }

        return this._urlUtils;
    }

    get pushNotificationService(): TsPushNotificationService {
        if (!this._pushNotificationService) {
            throw new Error('pushNotificationService not initialized');
        }

        return this._pushNotificationService;
    }

    get environmentDetect(): ITsEnvironmentDetect {
        if (!this._environmentDetect) {
            throw new Error('environmentDetect not initialized');
        }

        return this._environmentDetect;
    }

    get browserUtilsService(): IBrowserUtilsService {
        if (!this._browserUtilsService) {
            throw new Error('browserUtilsService not initialized');
        }

        return this._browserUtilsService;
    }
}

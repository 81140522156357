'use strict';

import {isSoloSubdomain} from '../../../services/ts-solo/ts-solo.service';

const _isSoloSubdomain = isSoloSubdomain();

require(_isSoloSubdomain ? './invite-solo.style.scss' : './invite.style.scss');

const inviteView = require(_isSoloSubdomain ? './invite-solo.view.html' : './invite.view.html');

function config($stateProvider) {
    $stateProvider.state('start.invite', {
        url: '/invite',
        template: inviteView,
        controller: () => ({}),
        controllerAs: 'invite'
    });
}

export default angular.module('states.invite', []).config(config);

import get from 'lodash/get';
import find from 'lodash/find';
import {MediaTypes} from '@techsee/techsee-common/lib/constants/account.constants';
import {STATUS_MESSAGES} from './meeting.settings';
import {getMeetingTracer} from './meeting.tracer';
import {FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import {KnownMediaStream, MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';
import {IMediaSubscriber} from '@techsee/techsee-media-service/lib/MediaContracts';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

const trace = getMeetingTracer('AudioService');

export enum ALERT_COLORS {
    ERROR = '#e3325b',
    SUCCESS = '#00CF49',
    CONNECTING = '#0056D8'
}

export interface IAudioService {
    isAllowAudio: boolean;
    isAudioSupported: boolean;
    audioMessageAlertColor: ALERT_COLORS;
    audioMessageAlertLabel: string;
    isAudioAlertAvailable: boolean;
    isAudioEnabled: boolean;
    displayAudioButtons: boolean;
    voipMicImage: string;
    audioDisabled: boolean;
    audioSubscribers: IMediaSubscriber[];

    agentStream(): IMediaSubscriber | undefined;

    agentHasAudioStream(): boolean;

    checkDashboardAudioHandshake(): void;

    establishAudioStream(): void;

    initAudioAlert(): void;

    createSubscriber(): Promise<void>;

    destroyAudioSubscriber(): void;

    toggleUserMicrophone(value?: boolean): void;

    setIfAudioEnable(): void;

    initAudioMute(): void;

    observerConnected(): Promise<void>;
}

export class AudioService implements IAudioService {
    static $inject = ['tsChatApi', 'mobileAppMediaService', 'currentUser', 'tsEventService', '$translate'];
    private _tsChatApi: any;
    private _mobileAppMediaService: any;
    private _currentUser: any;
    private _eventService: any;
    private _isAudioEnabledForCurrentRoom = false;
    private _translate: any;
    private _audioMessageAlertColor: ALERT_COLORS = ALERT_COLORS.CONNECTING;
    private _audioMessageAlertLabel = '';
    private _audioAlertTimer: any;
    private _isAudioAlertAvailable = false;
    private _audioSubscribers: IMediaSubscriber[] = [];
    private _audioClientFailed = false;
    private _enableAudioForObserver: boolean;
    private _audioHandshakeSuccess = false;

    // eslint-disable-next-line max-len
    constructor(
        tsChatApi: any,
        mobileAppMediaService: any,
        currentUser: any,
        tsEventService: any,
        isAudioEnabledForCurrentRoom: boolean,
        $translate: any,
        enableAudioForObserver: boolean
    ) {
        this._tsChatApi = tsChatApi;
        this._mobileAppMediaService = mobileAppMediaService;
        this._currentUser = currentUser;
        this._eventService = tsEventService;
        this._isAudioEnabledForCurrentRoom = isAudioEnabledForCurrentRoom;
        this._translate = $translate;
        this._enableAudioForObserver = enableAudioForObserver;

        this.observerConnected = this.observerConnected.bind(this);

        this.initHandlers();
    }

    private initHandlers() {
        this._mobileAppMediaService.onAudioStreamFailed(() => {
            this.audioStreamFailed();
        });

        this._mobileAppMediaService.onStartSwitchCamera(() => {
            this.destroyAudioSubscriber();
        });
    }

    private audioStreamFailed() {
        this.setAudioAlert(ALERT_COLORS.ERROR, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR');
        this._audioClientFailed = true;
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_HANDSHAKE_SUCCESS, false);
        this._audioHandshakeSuccess = false;
    }

    private setAudioAlert(color: ALERT_COLORS, label: string) {
        const audioAlertTimer = get(this._tsChatApi, 'accountSettings.audioAlertTimer');

        clearTimeout(this._audioAlertTimer);
        this._audioAlertTimer = null;
        this._isAudioAlertAvailable = true;
        this._audioMessageAlertColor = color;
        this._translate(label).then((translatedValue: string) => {
            this._audioMessageAlertLabel = translatedValue;
        });

        if (color !== ALERT_COLORS.CONNECTING) {
            this._audioAlertTimer = setTimeout(() => {
                this._isAudioAlertAvailable = false;
            }, audioAlertTimer);
        }
    }

    get audioSubscribers() {
        return this._audioSubscribers;
    }

    get isAllowAudio() {
        return (
            get(this._mobileAppMediaService, 'deviceSupportInfo.hasMicrophone') &&
            ((this._isAudioEnabledForCurrentRoom &&
                get(this._tsChatApi, 'accountSettings.mediaType') === MediaTypes.videoAndAudio) ||
                (get(this._tsChatApi, 'accountSettings.fsSettings.voiceOverIP') &&
                    get(this._tsChatApi, 'client.flowType') === FlowType.fs))
        );
    }

    //Indicates if audio can be enabled in current session (in not says if it approved audio by user. see isAudioEnabled).
    get isAudioSupported() {
        if (
            !get(this._mobileAppMediaService, 'deviceSupportInfo.hasMicrophone') ||
            (get(this._tsChatApi, 'dashboard.audioSupport') && get(this._tsChatApi, 'client.audioSupport') === false)
        ) {
            return false;
        }

        return this.isAllowAudio && get(this._tsChatApi, 'dashboard.audioSupport');
    }

    //Indicated if audio is supported on current session, and user approved the audio.
    get isAudioEnabled() {
        return this.isAudioSupported && get(this._tsChatApi, 'client.audioSupport') === true;
    }

    get audioMessageAlertColor() {
        return this._audioMessageAlertColor;
    }

    get audioMessageAlertLabel() {
        return this._audioMessageAlertLabel;
    }

    get isAudioAlertAvailable() {
        return this._isAudioAlertAvailable;
    }

    get displayAudioButtons() {
        return (
            this.isAudioEnabled &&
            get(this._tsChatApi, 'dashboard.audioSupport') &&
            get(this._tsChatApi, 'dashboard.audioHandshakeSuccess') !== false &&
            get(this._tsChatApi, 'client.audioHandshakeSuccess') !== false
        );
    }

    get voipMicImage() {
        if (this.audioMessageAlertColor === ALERT_COLORS.CONNECTING) {
            return 'audio-connecting.png';
        }

        return get(this._tsChatApi, 'client.audioMuted') === false ? 'audio-on.png' : 'audio-off.png';
    }

    get audioDisabled() {
        return this.audioMessageAlertColor === ALERT_COLORS.CONNECTING || !this._tsChatApi.areBothSidesConnected;
    }

    agentStream() {
        return find(
            this.audioSubscribers,
            (subscriber: IMediaSubscriber) =>
                subscriber && subscriber.streamType === KnownMediaStream.AGENT_AUDIO_STREAM
        );
    }

    agentHasAudioStream() {
        const agentStream = this.agentStream();

        return !!(agentStream && agentStream.hasAudio && agentStream.isPlaying);
    }

    checkDashboardAudioHandshake() {
        if (get(this._tsChatApi, 'dashboard.audioHandshakeSuccess') === false) {
            this.setAudioAlert(ALERT_COLORS.ERROR, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR');
        }
    }

    initAudioAlert() {
        this.setAudioAlert(ALERT_COLORS.CONNECTING, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_CONNECTING');

        if (this._audioClientFailed || get(this._tsChatApi, 'dashboard.audioHandshakeSuccess') === false) {
            this.setAudioAlert(ALERT_COLORS.ERROR, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR');
        }
    }

    establishAudioStream() {
        const isOpentok = this._mobileAppMediaService.mediaServiceType === MediaServiceType.OPENTOK;

        if (this.agentHasAudioStream()) {
            this.setAudioAlert(ALERT_COLORS.SUCCESS, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_SUCCESS');

            if (!this._audioHandshakeSuccess && isOpentok) {
                this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_MUTED, false);
            }

            this._audioHandshakeSuccess = true;
            this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_HANDSHAKE_SUCCESS, true);

            const isAudioMuted = get(this._tsChatApi, 'client.audioMuted');

            this.toggleUserMicrophone(isAudioMuted);

            if (!isOpentok) {
                this.initAudioMute();
            }
        }
    }

    destroyAudioSubscriber() {
        this._audioSubscribers = [];
    }

    createSubscriber() {
        return this._mobileAppMediaService
            .createSubscriber({
                container: document.querySelector('.audio-subscriber-container-agent'),
                streamType: KnownMediaStream.AGENT_AUDIO_STREAM
            })
            .then((audioSubscriber: IMediaSubscriber) => {
                trace.info('Audio subscriber created');
                this._audioSubscribers.push(audioSubscriber);
                this._audioSubscribers.forEach((audioSubscriber: IMediaSubscriber) => {
                    audioSubscriber.onStateChanged(() => {
                        trace.info('audioSubscriber state changed', audioSubscriber);
                    });
                    audioSubscriber.onDispose(() => {
                        trace.info('audioSubscriber was disposed');
                        this._audioSubscribers.filter(
                            (subscriber: IMediaSubscriber) => audioSubscriber.streamType !== subscriber.streamType
                        );
                    });
                });
            });
    }

    observerConnected() {
        if (!this.isAudioEnabled || !this._enableAudioForObserver) {
            return Promise.resolve();
        }

        return this._mobileAppMediaService
            .createSubscriber({
                container: document.querySelector('.audio-subscriber-container-observer'),
                streamType: KnownMediaStream.OBSERVER_AUDIO_STREAM
            })
            .then((audioSubscriber: IMediaSubscriber) => {
                trace.info('Audio subscriber created');
                this._audioSubscribers.push(audioSubscriber);
                audioSubscriber.onStateChanged(() => {
                    trace.info('audioSubscriber state changed', audioSubscriber);
                });
                audioSubscriber.onDispose(() => {
                    trace.info('audioSubscriber was disposed');
                    this._audioSubscribers.filter(
                        (subscriber: IMediaSubscriber) => audioSubscriber.streamType !== subscriber.streamType
                    );
                });
            });
    }

    setIfAudioEnable() {
        if (this.isAllowAudio) {
            this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_SUPPORT, true);

            try {
                this._mobileAppMediaService.enableVoipDuringSession();
            } catch (e) {
                trace.error('error while trying to enabling voip during session: ', e);
                this._audioClientFailed = true;
                this.setAudioAlert(ALERT_COLORS.ERROR, 'MEETING.VIEW.AUDIO_CONNECTIVITY_INDICATION_ERROR');
            }

            this._eventService.sendEventLog(
                get(this._currentUser, '_id'),
                get(this._tsChatApi, 'roomId'),
                STATUS_MESSAGES.AUDIO_SUPPORT_DETECTED
            );
            trace.info('Audio is enabled now');
        }
    }

    toggleUserMicrophone(value?: boolean) {
        const changedValue = value !== undefined ? value : !this._tsChatApi.client.audioMuted;

        this._mobileAppMediaService.pauseAudioStream(changedValue);
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.AUDIO_MUTED, changedValue);
        this._eventService.sendEventLog(
            get(this._currentUser, '_id'),
            get(this._tsChatApi, 'roomId'),
            this._tsChatApi.client.audioMuted ? STATUS_MESSAGES.AUDIO_MUTED : STATUS_MESSAGES.AUDIO_UNMUTED
        );
    }

    initAudioMute() {
        this._mobileAppMediaService.pauseAudioStream(get(this._tsChatApi, 'client.audioMuted'));
    }
}

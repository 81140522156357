import preCameraPermissionView from './pre-camera-permission.view.html';

class PreCameraPermissionController {
    constructor(preCameraPermissionService, $rootScope, $translate) {
        'ngInject';

        this._rootScope = $rootScope;
        this.service = preCameraPermissionService;
        this.translate = $translate;
        this.img = 'img/camera_checked_icon.png';
        this.cameraPreApprovalTitle = this.translate.instant('CAMERA_PREAPPROVAL.TITLE-1');
        this.cameraPreApprovalSubTitle = this.translate.instant('CAMERA_PREAPPROVAL.PERMISSION-ASK');
        this.cameraPreApprovalButtonText = this.translate.instant('CAMERA_PREAPPROVAL.ENABLE_CAMERA');
    }

    get _theme() {
        return this._rootScope.THEME || null;
    }

    get requireImage() {
        return this._rootScope.requireImage;
    }
}

export function PreCameraPermissionDirective() {
    'ngInject';

    return {
        template: preCameraPermissionView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {},
        controller: PreCameraPermissionController,
        controllerAs: 'preCamera'
    };
}
